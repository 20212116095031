<div [ngClass]="state" class="po-widget collapsible-widget">
  <div
    (click)="headerOnClick()"
    class="po-widget-header-title d-flex align-items-center justify-content-between"
  >
    <ng-container *ngIf="label || label === ''">
      <button
        (click)="arrowOnClick()"
        *ngIf="!arrowOnRight && show"
        class="po-widget-title-action header"
        posSelectable
      >
        <span class="po-icon po-icon-arrow-down icon"> </span>
        <span class="po-widget-title">{{ label }}</span>
      </button>

      <ng-content select="ni-collapsible-header"></ng-content>

      <button
        (click)="arrowOnClick()"
        *ngIf="arrowOnRight && show"
        class="po-widget-title-action header"
        posSelectable
      >
        <span class="po-icon po-icon-arrow-down icon"> </span>
        <span class="po-widget-title">{{ label }}</span>
      </button>
    </ng-container>

    <po-input
      (p-change-model)="searchChange($event)"
      *ngIf="searchPlaceholder"
      [p-placeholder]="searchPlaceholder"
      class="po-md-6 collapsible-search"
      name="search"
      p-icon="po-icon-search"
    >
    </po-input>

    <div *ngIf="!actions && searchPlaceholder" class="po-mr-2"></div>

    <div class="actions-right">
      <ng-content select=".actions"></ng-content>

      <ng-container *ngIf="actions">
        <po-button
          (p-click)="action.callback()"
          *ngFor="let action of actions"
          [ngClass]="action.class"
          [p-icon]="action.icon"
          [p-kind]="action.type"
          [p-label]="action.label"
          p-small="true"
        >
        </po-button>
      </ng-container>
    </div>
  </div>
  <div *ngIf="show" [@collapsible]="state" class="po-widget-body body">
    <div class="content">
      <ni-collapsible-body></ni-collapsible-body>
      <ng-content></ng-content>
    </div>
  </div>
</div>
