import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { PoModalAction, PoModalComponent } from '@po-ui/ng-components';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { convertToBoolean } from '../../utils/util';

@Component({
  selector: 'ni-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AvatarComponent),
      multi: true,
    },
  ],
})
export class AvatarComponent implements OnInit, ControlValueAccessor {
  @ViewChild(PoModalComponent)
  public modal: PoModalComponent;
  @ViewChild('fileInput') public fileInputRef: ElementRef<HTMLInputElement>;

  @Input() cancelLabel = '';
  @Input() confirmLabel = '';
  @Input() uploadPhotoLabel = '';
  @Input() removePhotoLabel = '';

  @Input() round = true;
  @Input() size = 140;
  @Input() textSizeRatio = 3;
  @Input() bgColor: string;
  @Input() fgColor = '#FFF';
  @Input() borderColor: string;
  @Input() style: any = {};
  @Input() cornerRadius = 0;

  public initials: string;
  public avatarStyle: any;
  public containerStyle: any;

  public imageChangedEvent: any;
  private croppedImage: any;

  private _src: string;
  private _name: string;
  private _disabled: boolean;

  get src(): string {
    return this._src;
  }

  @Input()
  set src(value: string) {
    this._src = value;
    if (value) this.avatarStyle = this.imageStyle();
  }

  get name(): string {
    return this._name;
  }

  @Input()
  set name(value: string) {
    this._name = value;
    this.fillInitials();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = convertToBoolean(value);
  }

  public confirm: PoModalAction = {
    label: this.confirmLabel,
    action: () => {
      this.writeValue(this.croppedImage);
      this.onChange(this.croppedImage);
      this.modal.close();
    },
  };

  public cancel: PoModalAction = {
    label: this.cancelLabel,
    action: () => {
      this.modal.close();
    },
  };

  public onChange = (src: string) => {};

  // Function to call when the input is touched (when a star is clicked).
  public onTouched = () => {};

  constructor() {}

  ngOnInit() {
    this.containerStyle = {
      width: this.size + 'px',
      height: this.size + 'px',
      borderRadius: this.round ? '100%' : this.cornerRadius + 'px',
    };

    if (this.src) {
      this.avatarStyle = this.imageStyle();
    } else {
      this.fallback();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(src: string): void {
    this.src = src;
  }

  fallback() {
    this.src = undefined;
    this.avatarStyle = this.initialsStyle();
  }

  fillInitials() {
    if (!this._name) return;

    this.initials = this._name
      .split(/[ ,.-]+/)
      .filter(name => name)
      .map(name => name[0])
      .reduce((initials, initial) => initials + initial, '');

    if (this.initials.length > this.textSizeRatio) {
      this.initials = this.initials.substr(0, this.textSizeRatio);
    }
  }

  private initialsStyle() {
    return {
      textAlign: 'center',
      border: this.borderColor ? '1px solid ' + this.borderColor : '',
      textTransform: 'uppercase',
      color: this.fgColor,
      backgroundColor: this.bgColor,
      font: Math.floor(this.size / this.textSizeRatio) + 'px Helvetica, Arial, sans-serif',
      lineHeight: this.size + 'px',
      ...this.style,
    };
  }

  private imageStyle() {
    return {
      maxWidth: '100%',
      borderRadius: this.round ? '50%' : this.cornerRadius + 'px',
      border: this.borderColor ? '1px solid ' + this.borderColor : '',
      width: this.size,
      height: this.size,
      ...this.style,
    };
  }

  public selectPhoto() {
    const fileInput = this.fileInputRef.nativeElement;
    fileInput.value = '';
    this.confirm.label = this.confirmLabel;
    this.cancel.label = this.cancelLabel;
    fileInput.click();
  }

  public removePhoto() {
    this.writeValue('');
    this.onChange('');
    this.avatarStyle = this.initialsStyle();
  }

  public fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
    this.modal.open();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
}
