import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "img[appHideMissing]",
  standalone: true
})
export class HideMissingDirective {
  constructor(private el: ElementRef) {}

  @HostListener("error")
  private onError() {
    this.el.nativeElement.alt = 'Erro interno - Imagem não encontrada'
  }
}
