import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'ni-preview-image',
  templateUrl: './preview-image.component.html',
  styleUrls: ['./preview-image.component.scss'],
})
export class PreviewImageComponent {
  @Output()
  removeImage = new EventEmitter();

  @Output()
  imageConverted = new EventEmitter();

  @Input()
  url: string | ArrayBuffer;

  @Input() order: number;

  @Input()
  @HostBinding('class')
  size: 'small' | 'bigger' = 'bigger';

  async selectFile(event) {
    const file = event.target.files[0];
    if (file) {
      const file64 = await this.readFile(file);
      this.imageConverted.emit(file64);
    }
    event.target.value = '';
  }

  removeImg() {
    this.removeImage.emit();
    this.url = undefined;
  }

  private readFile(data: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(data);
    });
  }
}
