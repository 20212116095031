import { Component, HostBinding, Input } from '@angular/core';
import { convertToBoolean } from '../../utils/util';

@Component({
  selector: 'ni-empty-content',
  template: `
    <ni-icon
      class="po-mb-4"
      [src]="icon"
      [size]="size"
      [width]="width"
    ></ni-icon>
    <h3 class="po-text-xlarge-light po-mb-3">{{ message }}</h3>

    <ng-content></ng-content>
  `,
  styles: [
    `
      :host {
        --icon-font-size: 7em;

        display: flex;
        width: 100%;
        height: 100%;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1.5rem;
      }

      :host.bordered {
        border: 2px dashed var(--color-primary-alpha-50);
      }
    `,
  ],
})
export class EmptyContentComponent {
  @Input() icon: string;
  @Input() message: string;
  @Input() size: 'small' | 'medium' | 'large';
  @Input() width: 'inherit' | 'default';

  _bordered = false;
  @HostBinding('class.bordered')
  @Input('bordered')
  set bordered(bordered: any) {
    bordered === ''
      ? (this._bordered = true)
      : (this._bordered = convertToBoolean(bordered));
  }

  get bordered() {
    return this._bordered;
  }

  constructor() {}
}
