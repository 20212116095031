<div class="">
  <a
    (click)="advancedSearchModal.open()"
    class="po-pb-2 po-text-link text-decoration-underline"
  >
    <span class="po-icon po-icon-search"></span>
    {{ 'common.advancedSearch' | transloco }}
  </a>
</div>
<po-modal
  #advancedSearchModal
  [p-primary-action]="modalActions.primary"
  [p-secondary-action]="modalActions.secondary"
  [p-title]="'Busca avançada'"
  p-size="sm"
>
  <div class="ni-modal-content">
    <form [formGroup]="advancedSearchForm">
      <div *ngFor="let field of arrayFields">
        <ng-container [ngSwitch]="field.type">
          <ng-container
            *ngSwitchCase="typeFields.Text"
            [ngTemplateOutlet]="text"
          ></ng-container>
          <ng-container
            *ngSwitchCase="typeFields.Number"
            [ngTemplateOutlet]="number"
          ></ng-container>
          <ng-container
            *ngSwitchCase="typeFields.Radio"
            [ngTemplateOutlet]="radio"
          ></ng-container>
          <ng-container
            *ngSwitchCase="typeFields.Select"
            [ngTemplateOutlet]="select"
          ></ng-container>
          <ng-container
            *ngSwitchCase="typeFields.Combo"
            [ngTemplateOutlet]="combo"
          ></ng-container>
          <ng-container
            *ngSwitchCase="typeFields.MultiSelect"
            [ngTemplateOutlet]="multiselect"
          ></ng-container>
          <ng-template #text>
            <po-input
              [class]="field.class"
              [formControlName]="field.formControlName"
              [p-label]="field.label"
            ></po-input>
          </ng-template>
          <ng-template #number>
            <po-number
              [class]="field.class"
              [formControlName]="field.formControlName"
              [p-label]="field.label"
            >
            </po-number>
          </ng-template>
          <ng-template #radio>
            <div [class]="field.class">
              <po-radio-group
                [formControlName]="field.formControlName"
                [p-columns]="field.options?.length"
                [p-label]="field.label | transloco"
                [p-options]="field.radioOptions"
              >
              </po-radio-group>
            </div>
          </ng-template>
          <ng-template #select>
            <div>
              <po-select
                [class]="field.class"
                [formControlName]="field.formControlName"
                [p-label]="field.label | transloco"
                [p-options]="field.selectOptions"
              ></po-select>
            </div>
          </ng-template>
          <ng-template #combo>
            <div>
              <div class="po-font-title">{{field.label}}</div>
              <p-dropdown
                [class]="field.class"
                [formControlName]="field.formControlName"
                [options]="field.comboOptions"
                [optionLabel]="field.fieldLabel"
                [optionValue]="field.fieldValue"
              ></p-dropdown>
            </div>
          </ng-template>
          <ng-template #multiselect>
            <div>
              <po-multiselect
                [class]="field.class"
                [formControlName]="field.formControlName"
                [p-label]="field.label | transloco"
                [p-options]="field.multiSelectOptions"
              ></po-multiselect>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </form>
  </div>
</po-modal>
