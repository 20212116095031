import { ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core';
import { ThemePickerComponent } from '../theme-picker';

@Component({
  selector: 'ni-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent {
  @ContentChild(ThemePickerComponent)
  themePicker: ThemePickerComponent;

  @Input() public title: string;
  @Input() public currentDate = new Date();

  constructor() {}
}
