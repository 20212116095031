<div class="header-gradient">
  <div
    class="po-row align-items-center"
    [ngClass]="showBackButton && 'po-pl-2'"
  >
    <a
      *ngIf="showBackButton"
      class="back-button po-pr-1"
      [routerLink]="backUrl"
    >
      <img src="assets/icons/back.png" alt="back" />
    </a>
    <ng-content></ng-content>
  </div>
</div>
