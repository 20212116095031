import {
  PoComboOption,
  PoMultiselectOption,
  PoRadioGroupOption,
  PoSelectOption,
} from '@po-ui/ng-components';

export const CLASS_GRID = 'po-sm-gc po-md-gc po-lg-gc po-xl-gc';

export interface AdvancedSearchFields {
  formControlName: string;
  label: string;
  type: AdvancedSearchTypeFields;
  gridColumns?: AdvancedSearchGridColumns;
  class?: string;
  options?: AdvancedSearchFieldsOptions[];
  radioOptions?: PoRadioGroupOption[];
  selectOptions?: PoSelectOption[];
  comboOptions?: PoComboOption[];
  endpoint?: string;
  fieldValue?: string;
  fieldLabel?: string;
  multiSelectOptions?: PoMultiselectOption[];
}

export interface AdvancedSearchFieldsOptions {
  value: string;
  label: string;
}

export interface AdvancedSearchFieldsDisclaimerChange {
  $id: string;
  label: string;
  property: string;
  value: unknown;
}

export enum AdvancedSearchTypeFields {
  Text = 'text',
  Date = 'Date',
  Number = 'number',
  Radio = 'radio',
  Select = 'select',
  Combo = 'combo',
  MultiSelect = 'multiselect',
}

export enum AdvancedSearchGridColumns {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Eight = 8,
  Nine = 1,
  Ten = 10,
  Eleven = 11,
  Twelve = 12,
}
