import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TableColumn } from '../interfaces/table-column.interface';
import { TableColumnIcon } from './table-column-icon.interface';
import { ColorPaletteService } from '../services/color-palette.service';

/**
 * @docsPrivate
 *
 * @description
 *
 * Componente responsável por exibir ícones nas colunas.
 */
@Component({
  selector: 'ni-table-column-icon',
  templateUrl: './table-column-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ColorPaletteService],
})
export class TableColumnIconComponent {
  private _icons: TableColumnIcon[] = [];

  /** Definição da coluna que utiliza os icones. */
  @Input('p-column') column: TableColumn;

  /** Lista de colunas com ícones. */
  @Input('p-icons') set icons(
    icons: Array<TableColumnIcon> | Array<string> | string
  ) {
    this._icons = this.convertToColumnIcon(icons);
  }

  get icons(): TableColumnIcon[] {
    return this._icons;
  }

  /** Dados da linha da tabela. */
  @Input('p-row') row: any;

  click(columnIcon: TableColumnIcon | any): void {
    const isAbleAction = !this.isDisabled(columnIcon);

    if (columnIcon.action && isAbleAction) {
      columnIcon.action(this.row, columnIcon);
    } else if (this.column.action && isAbleAction) {
      this.column.action(this.row, columnIcon);
    }
  }

  getColor(column: TableColumnIcon): string {
    const color =
      typeof column.color === 'function'
        ? column.color(this.row, column)
        : column.color;

    if (color) {
      return `po-text-${color}`;
    }
  }

  getIcon(column: TableColumnIcon) {
    return column.icon || column.value;
  }

  isClickable(columnIcon: TableColumnIcon): boolean {
    return !!(
      !this.isDisabled(columnIcon) &&
      (columnIcon.action || this.column.action)
    );
  }

  isDisabled(column: TableColumnIcon): boolean {
    return column.disabled ? column.disabled(this.row) : false;
  }

  trackByFunction(index: any) {
    return index;
  }

  private convertToColumnIcon(
    rowIcons: Array<TableColumnIcon> | Array<string> | string
  ): Array<TableColumnIcon> {
    if (Array.isArray(rowIcons)) {
      return (<any>rowIcons).map(rowIcon =>
        typeof rowIcon === 'string' ? { value: rowIcon } : rowIcon
      );
    }

    if (typeof rowIcons === 'string') {
      return [{ value: rowIcons }];
    }

    return [];
  }
}
