import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonToolbarComponent } from './button-toolbar.component';
import { PoButtonModule } from '@po-ui/ng-components';

@NgModule({
  declarations: [ButtonToolbarComponent],
  imports: [CommonModule, PoButtonModule],
  exports: [ButtonToolbarComponent, PoButtonModule],
})
export class ButtonToolbarModule {}
