<div
  [ngStyle]="containerStyle"
  class="avatar-container"
  [ngClass]="src ? 'transparent' : ''"
>
  <img
    *ngIf="src"
    [src]="src"
    [width]="size"
    [height]="size"
    [ngStyle]="avatarStyle"
    (error)="fallback()"
    class="avatar"
  />
  <div *ngIf="!src" [ngStyle]="avatarStyle" class="avatar">
    {{ initials }}
  </div>

  <a
    role="button"
    *ngIf="!disabled"
    (click)="selectPhoto()"
    [style.color]="bgColor"
    class="po-link po-font-text-smaller photo-upload"
    >{{ uploadPhotoLabel }}</a
  >

  <a
    role="button"
    *ngIf="src"
    (click)="removePhoto()"
    [style.color]="bgColor"
    class="po-link po-font-text-smaller photo-remove"
    >{{ removePhotoLabel }}</a
  >
</div>

<input #fileInput type="file" (change)="fileChangeEvent($event)" hidden />

<po-modal
  [p-title]="uploadPhotoLabel"
  [p-primary-action]="confirm"
  [p-secondary-action]="cancel"
>
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    (imageCropped)="imageCropped($event)"
    [maintainAspectRatio]="true"
    [resizeToWidth]="256"
    format="png"
    [aspectRatio]="1"
    [roundCropper]="true"
  ></image-cropper>
</po-modal>
