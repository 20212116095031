<div #tabs class="ni-tabs">
  <div
    #tabsParent
    class="ni-tabs-parent"
    [ngClass]="{
      'has-slider': showSliderButtons && sliderButtons,
      'full-width': fullWidth
    }"
  >
    <div #nav></div>
  </div>

  <div
    class="ni-tabs-slider"
    *ngIf="sliderButtons"
    [hidden]="!showSliderButtons"
  >
    <a
      href="javascript:void(0)"
      class="po-button-link po-font-text-bold po-pr-1"
      (click)="createTab()"
      >{{ createLabel }}</a
    >

    <ni-slider-buttons
      class="po-hidden-sm"
      (tapLeft)="prevScroll()"
      (tapRight)="nextScroll()"
      (pressLeft)="prevScroll()"
      (pressRight)="nextScroll()"
    >
    </ni-slider-buttons>
  </div>
</div>

<div class="ni-tabs-container">
  <ng-content></ng-content>
</div>
