import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { fromEvent, interval, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ni-slider-buttons',
  template: `
    <po-button
      p-icon="po-icon-arrow-left"
      (tap)="onTapLeft()"
      (press)="onPressLeft()"
    ></po-button>
    <div class="separator">|</div>
    <po-button
      p-icon="po-icon-arrow-right"
      (tap)="onTapRight()"
      (press)="onPressRight()"
    ></po-button>
  `,
  styleUrls: ['./slider-buttons.component.scss'],
})
export class SliderButtonsComponent implements OnDestroy {
  @Input() itemsListHtml: ElementRef;
  @Output() tapLeft = new EventEmitter();
  @Output() tapRight = new EventEmitter();
  @Output() pressLeft = new EventEmitter();
  @Output() pressRight = new EventEmitter();

  private scrollPressed: Subscription;

  ngOnDestroy() {
    if (this.scrollPressed) {
      this.scrollPressed.unsubscribe();
    }
  }

  public onTapLeft() {
    this.tapLeft.emit();
  }

  public onTapRight() {
    this.tapRight.emit();
  }

  public onPressLeft() {
    this.emitLongClick(this.pressLeft);
  }

  public onPressRight() {
    this.emitLongClick(this.pressRight);
  }

  private emitLongClick(emitter: EventEmitter<any>) {
    if (this.scrollPressed) {
      this.scrollPressed.unsubscribe();
    }

    this.scrollPressed = interval(400)
      .pipe(takeUntil(fromEvent(document.body, 'mouseup')))
      .subscribe(() => emitter.emit());
  }
}
