import { Component, HostBinding, Input } from '@angular/core';
import { convertToBoolean } from '../../../utils/util';

@Component({
  selector: 'ni-list-item',
  template: `
    <ng-content></ng-content>
  `,
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent {
  private _selected: boolean;
  private _clickable: boolean;
  private _border: boolean = true;

  get selected(): boolean {
    return this._selected;
  }

  @Input()
  @HostBinding('class.selected')
  set selected(value: boolean) {
    this._selected = convertToBoolean(value);
  }

  get clickable(): boolean {
    return this._clickable;
  }

  @Input()
  @HostBinding('class.po-clickable')
  set clickable(value: boolean) {
    this._clickable = convertToBoolean(value);
  }

  get border(): boolean {
    return this._border;
  }

  @Input()
  @HostBinding('class.bordered')
  set border(value: boolean) {
    this._border = convertToBoolean(value);
  }
}
