import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PoButtonModule,
  PoFieldModule,
  PoModalModule,
  PoWidgetModule,
} from '@po-ui/ng-components';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthService } from './services';
import { AuthConfigModel } from './models/auth-config.model';
import { AuthContainerComponent } from './components/containers/auth-container/auth-container.component';
import { LoginComponent } from './components/login/login.component';
import { TranslocoModule } from '@ngneat/transloco';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { LoginGuard } from './services/login-guard.service';

@NgModule({
  imports: [
    CommonModule,
    PoButtonModule,
    PoWidgetModule,
    PoFieldModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: '',
        component: AuthContainerComponent,
        children: [{ path: 'login', component: LoginComponent }],
        canActivate: [LoginGuard],
      },
    ]),
    TranslocoModule,
    PoModalModule,
  ],
  declarations: [
    LoginComponent,
    AuthContainerComponent,
    RecoverPasswordComponent,
  ],
  providers: [AuthService],
})
export class MihmoAuthModule {
  static forRoot(
    config: AuthConfigModel
  ): ModuleWithProviders<MihmoAuthModule> {
    return {
      ngModule: MihmoAuthModule,
      providers: [config.literals],
    };
  }
}
