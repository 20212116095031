import { Component, ElementRef, Input } from '@angular/core';
import { convertToBoolean } from '../../utils/util';

@Component({
  selector: 'ni-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() public backUrl: any;

  private _showBackButton = true;

  get showBackButton(): boolean {
    return this._showBackButton;
  }

  @Input()
  set showBackButton(value: boolean) {
    this._showBackButton = convertToBoolean(value);
  }

  constructor(public el: ElementRef) {}
}
