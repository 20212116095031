import { PoMenuItem } from '@po-ui/ng-components';
import { RoleTypeEnum } from '@recriar/mihmo-interfaces';

export class UserModel {
  name: string;
  email: string;
  email_verified?: string;
  picture: string;
  userId: string;

  //front-end control
  lang?: string;
  permissions?: AccessProfileWithSystemFeature;
  menu?: PoMenuItem[];

  roleType?: RoleTypeEnum;
  roleName?: string;
  companiesIds?: string[];
  medicCrm?: string;
  medicUf?: string;
}

export interface AccessProfileWithSystemFeature {
  [key: string]: {
    id: string;
    label: string;
    path: string;
    name: string;
    rules: {
      allow: boolean;
    };
  };
}

export interface AuthMenuAndPermissions {
  permissions?: AccessProfileWithSystemFeature;
  menu?: PoMenuItem[];
  roleType?: RoleTypeEnum;
  companiesIds?: string[];
  medicCrm?: string;
  medicUf?: string;
  roleName?: string;
}

export enum Options {
  Email,
  Sms,
}

export interface UserAndSquadId {
  userId: string;
  squadId: string;
}
