import { TranslocoConfig, TranslocoTestingModule } from '@ngneat/transloco';
import { HashMap, Translation } from '@ngneat/transloco/lib/types';

export function getTranslocoModule(
  langs: HashMap<Translation> = {},
  config: Partial<TranslocoConfig> = {}
) {
  return TranslocoTestingModule.forRoot({
    langs: langs,
    translocoConfig: {
      availableLangs: ['en', 'pt-BR'],
      defaultLang: 'pt-BR',
    }

  });
}
