import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { HttpService } from '@recriar/titan';
import { map, tap } from 'rxjs/operators';
import { CompanySimplified } from '@recriar/mihmo-interfaces';

@Injectable({
  providedIn: 'root',
})
export class CompanyAuthService {
  private readonly KEY_TOKEN = 'mihmo-admin.token';
  private readonly COMPANY = 'mihmo-admin.company';
  public redirectUrl = null;

  constructor(private userService: UserService, private httpService: HttpService) {}

  public authenticate(companyId: string) {
    const setToken = ({ newPasswordToken }) => this.setToken(newPasswordToken);
    const getUserFromToken = ({ newPasswordToken }) =>
      this.userService.createUserFromToken(newPasswordToken);
    const setUser = response => this.userService.setUser(response);

    return this.httpService
      .post(`/empresa/${companyId}`, {})
      .pipe(tap(setToken), map(getUserFromToken), tap(setUser));
  }

  setCompanySelected(obj: CompanySimplified) {
    this.setInStorage(obj);
  }

  setInStorage(company: CompanySimplified) {
    company = {
      ...(company.data || company),
      value: company._id || company.data?._id,
      label: company.nomeFantasia || company.data?.nomeFantasia,
    };

    localStorage.setItem(this.COMPANY, JSON.stringify(company));
  }

  public getCompany() {
    return JSON.parse(sessionStorage.getItem(this.COMPANY));
  }

  public checkCompany(): boolean {
    const company = this.getCompany();
    return !!company;
  }

  private setToken(token: string): void {
    localStorage.setItem(this.KEY_TOKEN, token);
  }
}
