import { Component, OnInit, ViewChild } from '@angular/core';
import {
  PoModalAction,
  PoModalComponent,
  PoNotificationService,
  PoToasterOrientation,
} from '@po-ui/ng-components';
import { Literals, Options } from '../../models';
import { Subscription } from 'rxjs';
import { LiteralsService, UserService } from '../../services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'auth-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss'],
})
export class RecoverPasswordComponent implements OnInit {
  @ViewChild('modal') public modal: PoModalComponent;
  recoverForm: FormGroup;
  public options = Options;

  readonly columnOptions = [{ label: 'E-mail', value: Options.Email }];

  public literals: Partial<Literals> = {};
  public title = this.literals.accessRecover;
  public labelEmail = this.literals.enterYourEmail;
  public recoverPasswordByEmail = this.literals.recoverPasswordByEmail;
  public contactSupport = this.literals.contactSupport;

  private subscriptions: Array<Subscription> = [];

  public modalActions: {
    primary: PoModalAction;
    secondary: PoModalAction;
  } = {
    primary: {
      label: this.literals.send,
      action: () => {
        this.userService
          .requestPasswordChange(this.recoverForm.value.email, this.literals.api)
          .subscribe(() => {
            this.notificationService.success({
              message: translate(this.literals.requestPasswordChange),
              orientation: PoToasterOrientation.Top,
            });
            this.modal.close();
          });
      },
    },
    secondary: {
      label: this.literals.cancel,
      action: () => this.modal.close(),
    },
  };

  constructor(
    private literalsService: LiteralsService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private notificationService: PoNotificationService
  ) {}

  private setLiterals(literals: Literals) {
    this.literals = literals;
    this.recoveryModalText();
  }

  ngOnInit() {
    this.recoverForm = this.formBuilder.group({
      optionsGroupType: [Options.Email],
      email: ['', Validators.required],
    });
    this.subscriptions.push(
      this.literalsService.getLiterals().subscribe(literals => this.setLiterals(literals))
    );
  }

  recoveryModalText() {
    this.modalActions.primary.label = this.literals.send;
    this.modalActions.secondary.label = this.literals.cancel;
    this.title = this.literals.accessRecover;
    this.labelEmail = this.literals.enterYourEmail;
    this.recoverPasswordByEmail = this.literals.recoverPasswordByEmail;
    this.contactSupport = this.literals.contactSupport;
  }

  openModal() {
    this.modal.open();
  }

  closeModal() {
    this.modal.close();
  }
}
