import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[niSelectable]',
})
export class SelectableDirective {
  @HostBinding('class.selected')
  @Input()
  public selected: boolean;
  @HostListener('click')
  public select() {
    this.selected = !this.selected;
  }
}
