import { InjectionToken } from '@angular/core';

/**
 * Various InjectionTokens shared across all platforms
 * Always suffix with 'Token' for clarity and consistency
 */

export const PlatformLanguageToken = new InjectionToken<string>(
  'PlatformLanguage'
);

export const ApiBaseUrlToken = new InjectionToken<string>('ApiBaseUrl');

export const AppStoragePrefixToken = new InjectionToken<string>(
  'ApplicationPrefixToken'
);

export const DisableCultureInterceptorToken = new InjectionToken<string>(
  'DisableCultureInterceptorToken'
);

export const DisableRetryPolicyToken = new InjectionToken<string>(
  'DisableRetryPolicyToken'
);

export const DisableErrorHandlerToken = new InjectionToken<string>(
  'DisableErrorHandlerToken'
);
