import { PoBreadcrumb } from '@po-ui/ng-components';

/**
 * @usedBy PageComponent
 *
 * @description
 *
 * Interface para o atributo `filter` do componente `page`.
 */

export interface Page {
  /** Objeto com propriedades do breadcrumb. */
  breadcrumb?: PoBreadcrumb;

  /** Título da página. */
  title?: string;
}

export interface PushNotification {
  customerid: string;
  title: string;
  body: string;
}

export interface SquadUser {
  calendarLink: string | null;
  calendarName: string;
  id: string;
  isAssistant: boolean;
  isResponsible: boolean;
  name: string;
  nameAndRole: string;
  role: string;
  roleId: string;
  roleName: string;
}
