import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeGroupComponent } from './badge-group.component';
import { BadgeModule } from '../badge';
import { PopoverModule } from '../popover';

@NgModule({
  declarations: [BadgeGroupComponent],
  imports: [CommonModule, BadgeModule, PopoverModule],
  exports: [BadgeGroupComponent],
})
export class BadgeGroupModule {}
