<po-modal #modal p-click-out="true" p-size="xl" p-title="Pesquisa de Saúde">
  <div class="ni-modal-content">
    <ul *ngIf="limesurveyList && limesurveyList.length ; else emptyContent"  class="main-ul">
      <li *ngFor="let item of limesurveyList" class="po-pb-5">
        <span class="po-md-12 po-pb-1 po-text-color-blue title-very-large">{{ item.title }} </span>

        <ul>
          <li *ngFor="let subItem of item.questions" class="po-row border-li">
            <div class="title-very-small gray bold po-md-6 question">{{ subItem.question }}</div>
            <div class="title-very-small gray po-md-6 answer">{{ subItem.answers[0] }}</div>
          </li>
        </ul>
      </li>
    </ul>
    <ng-template #emptyContent>
      Nada encontrado
    </ng-template>
  </div>
</po-modal>
