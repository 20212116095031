<po-modal
  #modal
  [p-primary-action]="modalActions.primary"
  [p-secondary-action]="modalActions.secondary"
  [p-title]="title"
>
  <form [formGroup]="recoverForm">
    <div class="po-row d-flex justify-content-center">
      <po-radio-group
        class="po-sm-12 po-md-12 po-p-1"
        name="radioGroupBasic"
        [p-options]="columnOptions"
        formControlName="optionsGroupType"
      ></po-radio-group>
      <po-email
        *ngIf="recoverForm.value.optionsGroupType === options.Email"
        [p-label]="labelEmail"
        class="po-sm-12 po-md-12"
        formControlName="email"
        p-required="true"
      ></po-email>
      <span class="po-sm-12 po-md-12">
        {{ contactSupport }}
      </span>
    </div>
  </form>
</po-modal>
