import { Component, HostBinding, Input } from '@angular/core';
import { PoActiveOverlayService, PoLanguageService, PoModalComponent } from '@po-ui/ng-components';
import { convertToBoolean } from '../../utils/util';

@Component({
  selector: 'ni-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent extends PoModalComponent {
  @Input('p-title') override title: string;

  /**
   *  Define se o conteúdo do modal será exibido no centro
   */
  private _centeredContent = false;

  get centeredContent(): boolean {
    return this._centeredContent;
  }

  @Input()
  @HostBinding('class.centeredContent')
  set centeredContent(value: boolean) {
    this._centeredContent = convertToBoolean(value);
  }

  /**
   *  Define se o modal será flexível e com o lado direito azul
   */
  private _collapsible = false;

  get collapsible(): boolean {
    return this._collapsible;
  }

  @Input()
  @HostBinding('class.collapsible')
  set collapsible(value: boolean) {
    this._collapsible = convertToBoolean(value);
  }

  /**
   *  Define se o modal será aberto com o lado direito expandido como padrão
   */
  private _expanded = false;

  get expanded(): boolean {
    return this._expanded;
  }

  @Input()
  @HostBinding('class.expanded')
  set expanded(value: boolean) {
    this._expanded = convertToBoolean(value);
  }

  /**
   *  Define se a seta para abrir/fechar o lado flexível do modal será exibida
   */
  private _hideArrow = false;

  get hideArrow(): boolean {
    return this._hideArrow;
  }

  @Input()
  @HostBinding('class.hideArrow')
  set hideArrow(value: boolean) {
    this._hideArrow = convertToBoolean(value);
  }

  constructor(
    poActiveOverlayService: PoActiveOverlayService,
    poLanguageService: PoLanguageService
  ) {
    super(poActiveOverlayService, poLanguageService);
  }

  closeOnEscapeKey(event) {
    this.close(true);
    this.closeModalOnEscapeKey(event);
  }

  closeOnClickOut(event) {
    if (this.clickOut && !this.modalContent.nativeElement.contains(event.target)) {
      this.close(true);
      this.onClickOut(event);
    }
  }
}
