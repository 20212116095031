<div
  *ngIf="!isHidden"
  [ngClass]="centeredContent ? 'custom' : ''"
  class="po-modal"
  tabindex="0"
  (keydown.esc)="closeOnEscapeKey($event)"
>
  <div class="po-modal-overlay">
    <div
      class="po-modal-container po-pb-2 po-pt-2"
      (click)="closeOnClickOut($event)"
    >
      <div class="po-modal-vertical-align">
        <div
          #modalContent
          class="po-modal-content po-modal-{{ size }}"
          tabindex="-1"
        >
          <div class="po-modal-content-left">
            <div class="po-modal-header">
              <div class="po-modal-title po-text-ellipsis">{{ title }}</div>

              <a
                *ngIf="!hideClose"
                class="po-modal-header-close-button"
                tabindex="0"
                (click)="close(true)"
              >
                <span class="po-icon po-icon-close"></span>
              </a>
            </div>

            <div class="po-modal-body">
              <ng-content select=".modal-content-left"></ng-content>
              <ng-content select=".ni-modal-content"></ng-content>
            </div>

            <div class="po-modal-footer">
              <ng-content select=".ni-modal-footer"></ng-content>
            </div>

            <ng-container *ngIf="collapsible">
              <div class="toggle-indicator" [hidden]="expanded"></div>

              <button
                class="toggle-button"
                (click)="expanded = !expanded"
                role="button"
                [hidden]="hideArrow"
              >
                <span
                  class="po-icon"
                  [ngClass]="
                    expanded ? 'po-icon-arrow-left' : 'po-icon-arrow-right'
                  "
                ></span>
              </button>
            </ng-container>
          </div>

          <ng-container *ngIf="collapsible">
            <div class="po-modal-content-right" [hidden]="!expanded">
              <ng-content select=".modal-content-right"></ng-content>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
