import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ni-quick-search',
  templateUrl: './quick-search.component.html',
  styleUrls: ['./quick-search.component.scss'],
})
export class QuickSearchComponent {
  @Output() quickSearchText = new EventEmitter<string>();
  @Input() clean = false;
  public searchText = '';

  private clickEventHandler: (event: Event) => void;
  buttonClearCombo: HTMLDivElement;

  constructor() {}

  quickSearch(search: string): void {
    this.quickSearchText.emit(search);
  }

  onChange($event: string) {
    if ($event.length > 0) {
      this.buttonClearCombo = document.querySelector('.po-icon-close');
      this.addEventListener();
    }
  }

  public addEventListener() {
    this.removeEventListener();

    if (this.buttonClearCombo == null) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.clickEventHandler = function () {
      // 'this' is not the class instance but that is
      that.quickSearch('');
    };
    this.buttonClearCombo.addEventListener('click', this.clickEventHandler);
  }

  public removeEventListener() {
    if (this.buttonClearCombo == null) {
      return;
    }

    // does remove the listener
    this.buttonClearCombo.removeEventListener('click', this.clickEventHandler);
  }
}
