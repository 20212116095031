import { Component } from '@angular/core';

import { PageBaseComponent } from './page-base.component';

/**
 * @docsPrivate
 *
 * @docsExtends PageBaseComponent
 */
@Component({
  selector: 'ni-page',
  templateUrl: './page.component.html',
})
export class PageComponent extends PageBaseComponent {}
