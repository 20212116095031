import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { convertToBoolean } from '../../utils/util';

@Component({
  selector: 'ni-badge',
  templateUrl: `./badge.component.html`,
  styleUrls: [`./badge.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input() color: string;

  private _small: boolean;

  get small(): boolean {
    return this._small;
  }

  @Input()
  @HostBinding('class.small')
  set small(value: boolean) {
    this._small = convertToBoolean(value);
  }
}
