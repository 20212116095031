import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { PopoverDirective } from '../../popover';
import { UserModel } from '@recriar/mihmo-auth';

export interface User {
  email: string;
  isAdmin: boolean;
  name: string;
}

export interface ToolbarMenuOption {
  label: string;
  icon: string;
  action: () => void;
}

@Component({
  selector: 'ni-toolbar-menu',
  templateUrl: './toolbar-menu.component.html',
  styleUrls: ['./toolbar-menu.component.scss'],
})
export class ToolbarMenuComponent {
  @ViewChild(PopoverDirective)
  popover: PopoverDirective;

  @Input() user: UserModel;
  @Input() icon = 'po-icon-user';
  @Input() hello = 'Hello';
  @Input() leftSideTitle = 'My profile';
  @Input() rightSideTitle = 'Options';
  @Input() textLogout = 'Logout';
  @Input() options: ToolbarMenuOption[] = [];
  @Input() changePasswordLabel = '';

  @Output() logout = new EventEmitter<void>();
  @Output() changePassword = new EventEmitter<void>();

  onSelectOption(option: ToolbarMenuOption) {
    this.popover.close();
    option.action();
  }
}
