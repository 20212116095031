import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TableColumnLabel } from './table-column-label.interface';
import { ColorPaletteService } from '../services/color-palette.service';

/**
 * @docsPrivate
 *
 * @description
 *
 * Componente para a criação da representação da legenda, em formato de texto .
 */

@Component({
  selector: 'ni-table-column-label',
  templateUrl: './table-column-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ColorPaletteService],
})
export class TableColumnLabelComponent {
  private _value: TableColumnLabel;

  /** Objeto com os dados do label */
  @Input('p-value') set value(value: TableColumnLabel) {
    if (value) {
      value.color = this.poColorPaletteService.getColor(value);
    }

    this._value = value;
  }
  get value(): TableColumnLabel {
    return this._value;
  }

  constructor(private poColorPaletteService: ColorPaletteService) {}
}
