import { AbstractControl } from '@angular/forms';
import { isEmptyInputValue } from '../utils/util';

export class DynamicValidators {
  public static lesserThan(attribute: string) {
    const result = (
      control: AbstractControl
    ): { [key: string]: boolean } | null => {
      if (!control || !control.parent) return null;

      const { value } = control.parent;
      const sibling = value[attribute];
      const currentValue = control.value;

      if (currentValue > sibling) {
        return { lesserThan: true };
      }

      return null;
    };
    return result;
  }

  public static greaterThan(attribute: string) {
    const result = (
      control: AbstractControl
    ): { [key: string]: boolean } | null => {
      if (!control || !control.parent) return null;

      const { value } = control.parent;
      const sibling = value[attribute];
      const currentValue = control.value;

      if (currentValue < sibling) {
        return { greaterThan: true };
      }

      return null;
    };
    return result;
  }

  public static requiredIf(attribute: string) {
    const result = (
      control: AbstractControl
    ): { [key: string]: boolean } | null => {
      if (!control || !control.parent) return null;

      const { value } = control.parent;
      const required = value[attribute];

      if (required && isEmptyInputValue(control.value)) {
        return { required: true };
      }

      return null;
    };
    return result;
  }
}
