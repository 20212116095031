<div class="d-flex align-items-center generic-search justify-content-end">
  <ni-advanced-search
    #advancedSearchComponent
    (disclaimerFields)="setDisclaimer($event)"
    (filterFields)="emitFilterFields($event)"
    *ngIf="advancedSearch"
    [arrayFields]="arrayFields"
    class="flex advanced-search"
  ></ni-advanced-search>
  <ni-quick-search
    (quickSearchText)="emitQuickSearchText($event)"
    *ngIf="quickSearch"
    class="quick-search po-md-6"
  ></ni-quick-search>
</div>
<div class="po-mb-2">
  <po-disclaimer-group
    (p-change)="filter($event)"
    [p-disclaimers]="disclaimerFields"
    [p-hide-remove-all]="true"
    class="advanced-search-disclaimer"
  >
  </po-disclaimer-group>
</div>
