import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

declare var ResizeObserver: any;

@Directive({
  selector: '[niObserveSize]',
})
export class ObserveSizeDirective implements OnInit, OnDestroy {
  @Output('niObserveSize')
  observeSizeChange = new EventEmitter();

  private resizeObserver;
  private observerNotification$ = new Subject();
  private observerSubscription: Subscription;

  constructor(private elementRef: ElementRef) {
    this.observerNotification$
      .pipe(debounceTime(50))
      .subscribe(entries => this.observeSizeChange.emit(entries));
  }

  ngOnInit() {
    this.resizeObserver = new ResizeObserver(entries => {
      this.observerNotification$.next(entries);
    });
    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy() {
    this.resizeObserver.unobserve(this.elementRef.nativeElement);
    this.observerSubscription?.unsubscribe();
  }
}
