import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AppStoragePrefixToken } from '../services/tokens';
import { tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { JwtHelperService } from '@auth0/angular-jwt';
import firebase from 'firebase';
import User = firebase.User;

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  private readonly STORAGE_KEY = 'token';

  constructor(
    @Inject(AppStoragePrefixToken) private storagePrefix: string,
    @Inject('auth') public firebase: AngularFireAuth
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.getToken();

    if (!this.isAuthenticated()) {
      this.refreshToken().then(async () => {
        return await this.nextHandle(request, next, token);
      })
    }

    return this.nextHandle(request, next, token);
  }

  nextHandle(request, next, token) {
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.status === 403) {
              this.clearToken();
              window.location.reload();
            }
          }
        }
      )
    );
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    const jwtHelper = new JwtHelperService();
    return token && !jwtHelper.isTokenExpired(token);
  }

  async refreshToken(): Promise<void> {
    await this.firebase.onAuthStateChanged(async function (user) {
      if (user) {
        return await AuthInterceptor.refreshAndSetToken(user);
      }
    });
  }

  static async refreshAndSetToken(user: User): Promise<void> {
    await user.getIdToken(true).then((token) => {
      localStorage.setItem('mihmo-admin.token', token);
      return;
    });
  }

  private getToken() {
    return localStorage.getItem(`${this.storagePrefix}.${this.STORAGE_KEY}`);
  }

  private clearToken() {
    return localStorage.removeItem(`${this.storagePrefix}.${this.STORAGE_KEY}`);
  }
}
