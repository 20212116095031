import { NgModule } from '@angular/core';
import { ToolbarComponent } from './toolbar.component';
import { CommonModule } from '@angular/common';
import { ToolbarMenuComponent } from './toolbar-menu/toolbar-menu.component';
import { PopoverModule } from '../popover';
import { AvatarModule } from '../avatar';
import { ListModule } from '../list';
import { DividerModule } from '../divider';
import { IconModule } from '../icon';
import { PoFieldModule, PoPopoverModule, PoButtonModule } from '@po-ui/ng-components';

@NgModule({
  declarations: [ToolbarComponent, ToolbarMenuComponent],
  imports: [
    CommonModule,
    PopoverModule,
    AvatarModule,
    ListModule,
    DividerModule,
    IconModule,
    PoFieldModule,
    PoPopoverModule,
    PoButtonModule,
  ],
  exports: [ToolbarComponent, ToolbarMenuComponent],
})
export class ToolbarModule {}
