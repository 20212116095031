import {
  Component,
  HostBinding,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PoPopoverComponent } from '@po-ui/ng-components';

@Component({
  selector: 'ni-popover',
  template: `
    <po-popover>
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </po-popover>
  `,
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent {
  @Input() content: TemplateRef<any>;

  @HostBinding('class.padding')
  @Input()
  padding: boolean;

  @ViewChild(PoPopoverComponent, { static: true })
  popover: PoPopoverComponent;

  close() {
    this.popover.close();
  }
}
