import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar.component';
import { PoModalModule } from '@po-ui/ng-components';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [AvatarComponent],
  imports: [CommonModule, PoModalModule, ImageCropperModule],
  exports: [AvatarComponent],
})
export class AvatarModule {}
