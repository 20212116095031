import { Component, EventEmitter, Input, Output } from '@angular/core';
import { collapsible } from '../../animations/collapsible.animation';
import { convertToBoolean } from '../../utils/util';
import { CollapsibleWidgetAction } from './collapsible-widget.model';

@Component({
  selector: 'ni-collapsible-widget',
  templateUrl: './collapsible-widget.component.html',
  styleUrls: ['./collapsible-widget.component.scss'],
  animations: [collapsible],
})
export class CollapsibleWidgetComponent {
  @Input() public label: string;
  @Input() public state: 'expanded' | 'collapsed' = 'expanded';
  @Input() actions: CollapsibleWidgetAction[];
  @Input() searchPlaceholder: string;
  @Input() toggleType: 'arrow' | 'header' = 'arrow';
  @Input() show = true;

  private _arrowOnRight: boolean;
  @Input()
  set arrowOnRight(value: boolean) {
    this._arrowOnRight = convertToBoolean(value);
  }

  get arrowOnRight() {
    return this._arrowOnRight;
  }

  @Output() search = new EventEmitter();
  @Output() changeState: EventEmitter<string> = new EventEmitter();

  private toggleState() {
    this.state =
      !this.state || this.state === 'collapsed' ? 'expanded' : 'collapsed';
    this.changeState.emit(this.state);
  }

  public headerOnClick() {
    if (this.toggleType === 'header') {
      this.toggleState();
    }
  }

  public arrowOnClick() {
    if (this.toggleType === 'arrow') {
      this.toggleState();
    }
  }

  public searchChange(text) {
    this.search.emit(text);
  }
}
