import { of, throwError } from 'rxjs';

export enum UserProfileTypes {
  Administrator = '0',
  Cashier = '1',
  Operator = '2',
  AdministratorRestrict = '3',
}

export enum UserProfileTypesErp {
  SuperAdmin = '0',
  Administrator = '1',
}

// export function checkHasPermission(allowedProfileTypes: string[]) {
//   const user = JSON.parse(localStorage.getItem('pos.user'));
//   let hasPermission = null;
//
//   if (user && allowedProfileTypes.length > 0) {
//     hasPermission = user.userProfilesTypeList.some(profile => {
//       return allowedProfileTypes.find(profileType => profileType === profile);
//     });
//   }
//   return hasPermission ? of(true) : throwError(false);
// }

export function checkHasPermissionErp(allowedProfileTypes: string[]) {
  const user = JSON.parse(localStorage.getItem('mihmo-admin.user') || '') ;
  let hasPermission = false;

  if (user && allowedProfileTypes) {
    hasPermission = !!allowedProfileTypes.find(profileType => profileType === user.nivel);
  }

  return hasPermission ? of(true) : throwError(false);
}
