import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AccessProfileWithSystemFeature } from '../models';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  private permissions: AccessProfileWithSystemFeature;
  constructor(private userService: UserService, private router: Router) {
    this.permissions = this.userService.getUserLogged().permissions;
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.permissions[route.routeConfig.path]) {
      return true;
    } else {
      this.router.navigate(['/error']);
      return null;
    }
  }
}
