import { NgModule } from '@angular/core';
import { ThemePickerComponent } from './theme-picker.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { StyleManager } from './style-manager/style-manager';
import { ThemeStorage } from './theme-storage/theme-storage';
import { PoFieldModule } from '@po-ui/ng-components';

@NgModule({
  imports: [CommonModule, FormsModule, PoFieldModule],
  exports: [ThemePickerComponent],
  declarations: [ThemePickerComponent],
  providers: [StyleManager, ThemeStorage],
})
export class ThemePickerModule {}
