import { NgModule } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { map } from 'rxjs/operators';
import {
  MihmoAuthModule,
  Literals,
  LiteralsService,
} from '@recriar/mihmo-auth';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@NgModule({
  imports: [
    MihmoAuthModule.forRoot({
      literals: {
        provide: LiteralsService,
        deps: [TranslocoService],
        useFactory: (transloco: TranslocoService) => {
          return {
            getLiterals: (): Observable<Literals> =>
              transloco
                .selectTranslate([
                  'auth.welcome',
                  'auth.insertLogin',
                  'auth.insertPassword',
                  'auth.userRemember',
                  'auth.enter',
                  'auth.accessRecover',
                  'common.send',
                  'common.cancel',
                  'auth.enterYourEmail',
                  'auth.recoverPasswordByEmail',
                  'auth.contactSupport',
                  'auth.requestPasswordChange',
                ])
                .pipe(
                  map((t) => ({
                    appName: 'Recriar Admin',
                    version: environment.version,
                    api: environment.api,
                    apiNest: environment.apiNest,
                    welcome: t[0],
                    loginPlaceholder: t[1],
                    passwordPlaceholder: t[2],
                    rememberUserLabel: t[3],
                    enter: t[4],
                    accessRecover: t[5],
                    send: t[6],
                    cancel: t[7],
                    enterYourEmail: t[8],
                    recoverPasswordByEmail: t[9],
                    contactSupport: t[10],
                    requestPasswordChange: t[11],
                  }))
                ),
          };
        },
      },
    }),
  ],
})
export class AuthRootModule {}
