import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { convertToBoolean } from '../../utils/util';

@Component({
  selector: 'ni-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent {
  @Input() items: any[];
  @Input() itemTemplate: TemplateRef<any>;

  @Output() dropListDropped = new EventEmitter<any>();
  @Output() dropListSorted = new EventEmitter<any>();

  private _draggable: boolean;

  get draggable(): boolean {
    return this._draggable;
  }

  @Input()
  set draggable(value: boolean) {
    this._draggable = convertToBoolean(value);
  }

  @Input() listItemBorder = true;
}

@Component({
  selector: 'ni-list-header, ni-list-content',
  template: ` <ng-content></ng-content> `,
})
export class ListContentContainerComponent {}
