import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpParams,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { AppStoragePrefixToken } from '../services/tokens';

@Injectable({ providedIn: 'root' })
export class ApiCultureInterceptor implements HttpInterceptor {
  private readonly STORAGE_KEY = 'user';

  constructor(@Inject(AppStoragePrefixToken) private storagePrefix: string) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const user = <User>(
      JSON.parse(
        localStorage.getItem(`${this.storagePrefix}.${this.STORAGE_KEY}`) || ''
      )
    );

    if (user && user.preferredCulture) {
      let httpParams = new HttpParams({
        fromString: request.params.toString(),
      });
      httpParams = httpParams.append('culture', user.preferredCulture);
      request = request.clone({
        params: httpParams,
      });
    }

    return next.handle(request);
  }
}
