import { Inject, Injectable } from '@angular/core';
import { LoginForm } from '../models/login-form';
import { UserService } from './user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpService } from '@recriar/titan';
import { TranslocoService } from '@ngneat/transloco';
import { AuthMenuAndPermissions, Literals, UserModel } from '../models';
import { LiteralsService } from './literals.service';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../../../../../apps/admin/src/environments';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: Observable<any>;
  private literals: Literals;

  public redirectUrl = null;
  private readonly KEY_TOKEN = 'mihmo-admin.token';

  constructor(
    private httpService: HttpService,
    private userService: UserService,
    private translocoService: TranslocoService,
    private literalsService: LiteralsService,
    @Inject('auth') private firebase: AngularFireAuth
  ) {
    this.firebase.authState.subscribe({
      next: () => () => ({}),
      error: (err) => console.log('err', err),
    });
    this.literalsService.getLiterals().subscribe((literals: Literals) => {
      this.literals = literals;
    });
  }

  async authenticateFirebase(params: LoginForm) {
    return this.firebase
      .signInWithEmailAndPassword(params.username, params.password)
      .then(async (response) => {
        return response.user.getIdToken().then(async (token) => token);
      });
  }

  public async authenticate(params: LoginForm) {
    this.clearLocalStorage();
    await this.authenticateFirebase(params)
      .then(async (token) => {
        try {
          this.setToken(token);
          await this.getPermissionsAndMenu()
            .toPromise()
            .then(async (permissionAndMenuList) => {


              const user = this.userService.createUserFromToken(token);
              this.setLang(user);
              this.userService.setUser(user);

              const permissionAndMenuListMapped = {
                ...user,
                permissions: permissionAndMenuList?.permissions,
                menu: permissionAndMenuList?.menu,
                roleType: permissionAndMenuList?.roleType,
                companiesIds: permissionAndMenuList?.companiesIds,
                medicCrm: permissionAndMenuList?.medicCrm,
                roleName: permissionAndMenuList?.roleName,
                medicUf: permissionAndMenuList?.medicUf,
              };

              this.userService.setUser(permissionAndMenuListMapped);
            })
            .catch(() => {
              this.clearLocalStorage();
            });
        } catch (error) { /* empty */ }
      })
      .catch(() => {
        this.clearLocalStorage();
      });
  }

  getPermissionsAndMenu(): Observable<AuthMenuAndPermissions> {
    return this.httpService
      .disableApiPrefix()
      .post(`${environment.apiNest}/authentication/logon`, {});
  }

  public authenticateSso(token: string) {
    this.setToken(token);
    const user = this.userService.createUserFromToken(token);
    this.userService.setUser(user);
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    const jwtHelper = new JwtHelperService();
    return token && !jwtHelper.isTokenExpired(token);
  }

  public setToken(token: string): void {
    localStorage.setItem(this.KEY_TOKEN, token);
  }

  public getToken() {
    return localStorage.getItem(this.KEY_TOKEN);
  }

  public logout() {
    localStorage.clear();
    sessionStorage.clear();
  }

  public setLang(user: UserModel) {
    const { lang } = user;

    this.translocoService.load(lang);
    localStorage.setItem('lang', lang);
  }

  private clearLocalStorage() {
    localStorage.removeItem('mihmo-admin.user');
    localStorage.removeItem('mihmo-admin.token');
    sessionStorage.removeItem('mihmo-admin.token_twilio');
    sessionStorage.removeItem('mihmo-accompaniment-selected');
  }
}
