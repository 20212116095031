/**
 * @docsPrivate
 *
 * @description
 *
 * O componente **page** é utilizado como container principal para os componentes page-header, page-content
 * e para as ações dos componentes page-edit e page-detail.
 *
 * Quando estiver sendo utilizado o componente menu junto ao page, ambos devem estar no mesmo nível
 * e inseridos em uma div com a classe **wrapper**. Esta classe será responsável por fazer os cálculos
 * necessários de alinhamento dos componentes.
 *
 * O componente **page** também pode ser utilizado sem o menu e neste caso o corpo da página deve ser
 * definido com a altura de 100% para que o page maximize seu tamanho.
 * ```
 * html, body {
 *   height:100%;
 * }
 * ```
 */
export class PageBaseComponent {}
