import { Injectable } from '@angular/core';
import { ColorPaletteEnum } from '../enums/color-palette.enum';

@Injectable()
export class ColorPaletteService {
  getColor(value: { color?: string; type?: string }): string {
    return (<any>Object).values(ColorPaletteEnum).includes(value.color)
      ? value.color
      : value.type
      ? this.getColorFromType(value.type)
      : value.color;
  }

  private getColorFromType(type: string): ColorPaletteEnum {
    switch (type) {
      case 'danger':
        return ColorPaletteEnum.Color07;
      case 'success':
        return ColorPaletteEnum.Color11;
      case 'warning':
        return ColorPaletteEnum.Color08;
      default:
        return null;
    }
  }
}
