<div class="d-flex flex-row align-items-center">
  <div class="ni-menu-switcher">
    <div
      class="cursor-pointer d-flex align-items-center justify-content-between"
      [niPopover]="menu"
      position="bottom-center"
    >
      <div class="po-ml-1 po-mr-1 cursor-pointer cut-text">
        {{ hello }}!
      </div>

      <a role="button">
        <img class="popover-menu" src="assets/icons/profile.svg" />
      </a>
    </div>
  </div>
</div>

<ng-template #menu>
  <div class="toolbar-menu">
    <div class="po-row">
      <div class="left-toolbar po-sm-12 po-lg-5 po-p-2 po-hidden-sm">
        <div class="po-text-center">
          <ni-avatar [name]="user?.name" [size]="64" [disabled]="true"></ni-avatar>
          <h4 class="po-mt-1 po-font-text-large-bold po-text-color-white change-user">
            {{ user?.name }}
          </h4>

          <p class="po-font-text-small po-text-color-white cut-email">
            {{ user?.email }}
          </p>

          <a
            *ngIf="changePasswordLabel"
            class="po-font-text-small po-text-color-white cut-email po-pt-2"
            href="javascript:void(0)"
            (click)="changePassword.emit()"
          >
            {{ changePasswordLabel }}
          </a>
        </div>
      </div>

      <div class="toolbar-menu-right po-sm-12 po-lg-7 po-p-2 po-pr-0">
        <div class="po-pr-2">
          <ni-list>
            <ni-list-content>
              <ng-container *ngFor="let option of options; let i = index">
                <ni-list-item
                  *ngIf="option.action"
                  [border]="i < options?.length - 1"
                  class="d-flex align-items-center"
                  (click)="onSelectOption(option)"
                  [clickable]="true"
                >
                  <img
                    class="toolbar-menu-icon po-m-1 po-ml-0 po-mr-2"
                    [src]="option.icon"
                    alt="option.label"
                  />
                  <span class="po-icon {{ option.icon }}"></span>
                  {{ option.label }}
                </ni-list-item>
              </ng-container>
            </ni-list-content>
          </ni-list>
        </div>
      </div>
    </div>
  </div>
</ng-template>
