import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ContentChildren,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import { TabButtonComponent } from './tab-button/tab-button.component';
import { TabComponent } from './tab/tab.component';
import { Subscription } from 'rxjs';
import { convertToBoolean, isMobile } from '../../utils/util';

@Component({
  selector: 'ni-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit, AfterViewInit, OnDestroy {
  /** Usado para indicar se a aba será pequena */
  small = false;

  isMobile = isMobile();

  @Input('small')
  set setSmall(small: string) {
    small === '' ? (this.small = true) : (this.small = convertToBoolean(small));
  }

  /** Indica se as tabs devem preencher 100% da largura */
  fullWidth = false;

  @Input('fullWidth')
  set setFullWidth(fullWidth: string) {
    fullWidth === '' ? (this.fullWidth = true) : (this.fullWidth = convertToBoolean(fullWidth));
  }

  editable = false;

  @Input('editable')
  set setEditable(editable: string | boolean) {
    editable === '' ? (this.editable = true) : (this.editable = convertToBoolean(editable));
  }

  @Input() createLabel;

  // Indica se deve ser exibido os botões de navegação das tabs
  sliderButtons = true;

  @Input('sliderButtons')
  set setSliderButtons(sliderButtons: string) {
    sliderButtons === ''
      ? (this.sliderButtons = true)
      : (this.sliderButtons = convertToBoolean(sliderButtons));
  }

  @Output() created = new EventEmitter();

  // Descobre os componentes das abas filhas
  @ContentChildren(TabComponent) tabsChild: QueryList<TabComponent>;

  // Obtem o ViewContainerRef do um objeto cuja variável local é #nav
  @ViewChild('nav', { read: ViewContainerRef, static: true })
  nav: ViewContainerRef;

  @ViewChild('tabsParent', { read: ViewContainerRef })
  tabsParent;

  @ViewChild('tabs', { read: ViewContainerRef })
  tabs;

  // Id do componente
  listButtons: Array<any> = [];
  showSliderButtons: boolean;

  private buttonSubscription: Subscription;
  private SCROLL_PIXELS = 250;
  private initialized = false;

  constructor(private resolver: ComponentFactoryResolver) {}

  ngAfterContentInit() {
    this.createTabButtons();

    this.tabsChild.changes.subscribe(() => {
      this.createTabButtons();
      this.enableActiveButton();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => this.enableActiveButton());
  }

  createTabButtons() {
    const ComponentFactory = this.resolver.resolveComponentFactory(TabButtonComponent);

    // Clear nav buttons
    this.listButtons = [];
    this.nav.clear();

    // Percorre tabs
    this.tabsChild.forEach(tab => {
      // Cria os botões
      const compButton = this.nav.createComponent(ComponentFactory);

      compButton.instance.label = tab.label;
      compButton.instance.id = tab.id;
      compButton.instance.active = tab.active;
      compButton.instance.hide = tab.hide;
      compButton.instance.disabled = tab.disabled;
      compButton.instance.small = this.small;
      compButton.instance.clickCallback = tab.tabClick;
      compButton.instance.icon = tab.icon;

      // É necessário esta arrow function para não perder o contexto.
      // Caso contrário, a funcão não teria mais acesso ao "this" deste component
      this.buttonSubscription = compButton.instance.clickTab.subscribe(id => this.selectedTab(id));
      this.listButtons.push(compButton.instance);
    });

    // const compSlider = this.nav.createComponent(sliderComponentFactory);
  }

  enableActiveButton() {
    // Váriaveis utilizadas para trocar aba ativa,
    // quando a aba ativa está desabilitada ou escondida
    let changeTab = false;
    let lastEnabledTab = null;
    let lastEnabledButton = null;

    this.tabsChild.forEach(tab => {
      this.listButtons.forEach(button => {
        if (button.id === tab.id) {
          if (tab.disabled || tab.hide) {
            // Verifica se uma desativada ou escondida está ativa
            if (tab.active) {
              tab.active = false;
              button.active = false;
              changeTab = true;
            }
          } else {
            // Armazena a aba que não esteja escondida para trocar de aba automaticamente
            // caso a aba ativa seja escondida ou desabilitada.
            if (!changeTab) {
              lastEnabledTab = tab;
              lastEnabledButton = button;
            } else if (!lastEnabledTab) {
              lastEnabledTab = tab;
              lastEnabledButton = button;
            }
          }

          // Atualiza as propriedades disabled e hide das abas
          button.disabled = tab.disabled;
          button.hide = tab.hide;
        }
      });
    });

    // Habilita uma aba ativa
    if (changeTab && lastEnabledTab) {
      lastEnabledTab.active = true;
      lastEnabledButton.active = true;
    }

    this.onResize();

    if (this.initialized) {
      this.scrollToEnd();
    } else if (this.listButtons.length > 0) {
      this.initialized = true;
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    const boxWidth = this.tabs?.element.nativeElement.getBoundingClientRect().width;
    const toScrollWidth = this.tabsParent?.element.nativeElement.scrollWidth;

    this.showSliderButtons = toScrollWidth > boxWidth + 1;
  }

  ngOnDestroy() {
    if (this.buttonSubscription) {
      this.buttonSubscription.unsubscribe();
    }
  }

  // Função disparada para trocar abas
  selectedTab(id) {
    this.tabsChild.forEach(tab => {
      tab.active = tab.id === id;
    });

    this.listButtons.forEach(button => {
      button.active = button.id === id;
    });
  }

  createTab() {
    this.created.emit();
  }

  public prevScroll() {
    const container = this.nav.element.nativeElement.parentElement;
    this.scrollTo(container, container.scrollLeft - this.SCROLL_PIXELS);
  }

  public nextScroll() {
    const container = this.nav.element.nativeElement.parentElement;
    this.scrollTo(container, container.scrollLeft + this.SCROLL_PIXELS);
  }

  public scrollToEnd() {
    const container: HTMLElement = this.nav.element.nativeElement.parentElement;
    setTimeout(() => this.scrollTo(container, container.scrollWidth));
  }

  private scrollTo(container: HTMLElement, width: number) {
    container.scrollTo({
      left: width,
      behavior: 'smooth',
    });
  }
}
