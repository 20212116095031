import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ni-badge-group',
  templateUrl: './badge-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeGroupComponent {
  @Input() rows: string[];
  badgeListSelected = [];

  selectBadgeList(badgeList: any[]) {
    if (badgeList && badgeList.length) {
      this.badgeListSelected = [...badgeList];

      // Remove os dois primeiros elementos do array
      this.badgeListSelected.shift();
      this.badgeListSelected.shift();
    }
  }
}
