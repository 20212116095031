import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  PoModalAction,
  PoModalComponent,
  PoNotificationService,
  PoToasterOrientation,
} from '@po-ui/ng-components';
import { translate } from '@ngneat/transloco';
import { PushNotification } from '../page.interface';

@Component({
  selector: 'ni-page-notification-button',
  templateUrl: './page-notification-button.component.html',
  styleUrls: ['./page-notification-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageNotificationButtonComponent {
  @ViewChild('sendNotificationModal') sendNotificationModal: PoModalComponent;
  @Input() userSelected: any;
  @Input() userLogged: any;
  @Output() sendPushNotification = new EventEmitter<PushNotification>();

  public body = '';
  notificationForm: FormGroup;

  cancelButton: PoModalAction = {
    action: () => {
      this.closeModal();
    },
    label: translate('common.cancel'),
  };

  confirmButton: PoModalAction = {
    action: () => {
      this.onSubmit(this.notificationForm);
    },
    label: translate('common.send'),
  };

  constructor(
    private formBuilder: FormBuilder,
    private poNotificationService: PoNotificationService
  ) {
    this.notificationForm = this.formBuilder.group({
      body: ['', [Validators.required, Validators.maxLength(100)]],
    });
  }

  openModal(): void {
    this.sendNotificationModal.open();
  }

  closeModal(): void {
    this.sendNotificationModal.close();
  }

  onSubmit({
    value,
    valid,
  }: {
    value: PushNotification;
    valid: boolean;
  }): void {
    if (!valid) {
      this.notificationForm.controls.body.markAsDirty();
      return this.poNotificationService.warning({
        message: translate('common.requiredField'),
        orientation: PoToasterOrientation.Top,
      });
    }

    value.title = 'Você recebeu uma nova mensagem';
    value.customerid = this.userSelected.id;

    this.sendNotificationModal.close();
    this.sendPushNotification.emit(value);
    this.notificationForm.reset();
  }
}
