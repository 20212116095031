import { Component, ElementRef, forwardRef, Input } from '@angular/core';
import { PoRadioGroupComponent } from '@po-ui/ng-components';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ni-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true,
    },
  ],
})
export class RadioGroupComponent extends PoRadioGroupComponent {
  @Input('p-template') public template;
  @Input('p-padding') public padding = true;

  public radioGroup: ElementRef<PoRadioGroupComponent>;
}
