<div
  class="image-background card"
  [style]="url ? ('background-image: ' + 'url(' + url + ')') : ''"
  [ngClass]="{ 'background-color': !url }"
>
  <div class="button-plus">
    <div class="image-order">{{order}}</div>
    <div class="add-image" (click)="changeImage.click()">
      <img *ngIf="!url" src="assets/images/plus.svg" />
    </div>
    <div *ngIf="url" class="image-removed">
      <img src="assets/images/delete.svg" (click)="removeImg()" />
    </div>
  </div>
  <input
    #changeImage
    type="file"
    accept="image/*"
    hidden
    (change)="selectFile($event)"
  />
</div>
