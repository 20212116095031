import {
  Component,
  ElementRef,
  AfterViewChecked,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { convertToBoolean } from '../../../utils/util';

@Component({
  selector: 'ni-tab',
  template: `
    <div class="ni-tab"><ng-content></ng-content></div>
  `,
  styles: [
    `
      .ni-tab {
        background: var(--tab-bg-color);
        overflow-y: auto;
        height: 100%;
      }
    `,
  ],
})
export class TabComponent implements AfterViewChecked {
  /** Label que aparecerá na aba */
  @Input() label: string;

  /** Icone que será mostrado na aba */

  @Input() icon: string;

  /** Para a aba parecer ativa e mostrar o conteúdo quando iniciar */
  _active = false;
  @Input('active')
  set active(active: any) {
    active === ''
      ? (this._active = true)
      : (this._active = convertToBoolean(active));
  }

  get active() {
    return this._active;
  }

  /** Esconder a aba */
  _hide = false;
  @Input('hide')
  set hide(hide: any) {
    hide === '' ? (this._hide = true) : (this._hide = convertToBoolean(hide));
  }

  get hide() {
    return this._hide;
  }

  /** Desabilitar a aba */
  _disabled = false;
  @Input('disabled')
  set disabled(disabled: any) {
    disabled === ''
      ? (this._disabled = true)
      : (this._disabled = convertToBoolean(disabled));
  }

  get disabled() {
    return this._disabled;
  }

  /** ID da aba */
  @Input() id?: string = String(Math.random());

  /** Método disparado ao clicar na aba */
  @Output() tabClick = new EventEmitter();

  constructor(private el: ElementRef) {}

  ngAfterViewChecked() {
    this.el.nativeElement.style.display = this.active ? '' : 'none';
  }
}
