import { Component, Input } from '@angular/core';
import { PoBreadcrumb } from '@po-ui/ng-components';

/**
 * @docsPrivate
 * @docsExtends PageHeaderBaseComponent
 */
@Component({
  selector: 'ni-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  private _breadcrumb: PoBreadcrumb;

  /** Objeto com propriedades do breadcrumb. */

  @Input('p-breadcrumb') set breadcrumb(value: PoBreadcrumb) {
    this._breadcrumb = value;
  }
  get breadcrumb(): PoBreadcrumb {
    return this._breadcrumb;
  }

  /** Título da página. */
  @Input('p-title') title: string;

  @Input('p-back-url') backUrl: any[] | string;

  @Input() loading = false;
}
