<ni-table-icon
  *ngFor="let column of icons; trackBy: trackByFunction"
  [p-clickable]="isClickable(column)"
  [p-color]="getColor(column)"
  [p-disabled]="isDisabled(column)"
  [p-icon]="getIcon(column)"
  [p-icon-tooltip]="column.tooltip"
  (p-click)="click(column)"
>
</ni-table-icon>
