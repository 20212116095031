import { AbstractControl, FormArray, FormGroup, ValidationErrors } from '@angular/forms';
import {
  PoBreadcrumb,
  PoBreadcrumbItem,
  PoTableColumn,
  PoTableColumnLabel,
  PoTableColumnSortType,
} from '@po-ui/ng-components';
import { PoUploadFile } from '@po-ui/ng-components/lib/components/po-field/po-upload/po-upload-file';
import { translate } from '@ngneat/transloco';
import * as Fuse from 'fuse.js';


// Idiomas suportados pelas páginas
export const poLocales = ['pt', 'en', 'es'];
// Idioma padrão
export const poLocaleDefault = 'pt';

/**
 * @deprecated
 * Utilize o método `getShortBrowserLanguage`.
 *
 * @description
 * Retorna idioma do browser ou o idioma padrão.
 */
export function browserLanguage() {
  return getShortBrowserLanguage();
}

/**
 * Retorna o idioma atual do navegador
 */
export function getBrowserLanguage(): string {
  // navigator.userLanguage is the value for IE10
  const language = navigator.language || navigator['userLanguage'];
  const shortLanguage = getShortLanguage(language);

  return poLocales.includes(shortLanguage) ? language : poLocaleDefault;
}

/**
 * Retorna o idioma do navegador, com somente as duas primeiras letras. Por exemplo: "pt" ou "es".
 *
 * Caso o valor retornado pelo navegador não estiver dentro dos idiomas suportados pelo PO,
 * será retornado a linguagem padrão (poLocaleDefault).
 */
export function getShortBrowserLanguage(): string {
  return getShortLanguage(getBrowserLanguage());
}

/**
 * Retorna o idioma com somente a abreviação do idioma (duas primeiras letras).
 * Por exemplo: "pt" ou "es".
 *
 * @param language {string} linguagem.
 *
 * @returns sigla do idioma padrão {string}.
 *
 * @default pt
 */
export function getShortLanguage(language: string): string {
  return (language || poLocaleDefault).toLowerCase().substring(0, 2);
}

export function isLanguage(value) {
  const languageRegex = new RegExp('^[a-z]{2}(-[a-z]{2})?$', 'i');

  return languageRegex.test(value);
}

/* istanbul ignore next */
export function reloadCurrentPage() {
  window.location.assign(location.href);
}

export function convertToBoolean(val: any): boolean {
  if (typeof val === 'string') {
    val = val.toLowerCase().trim();
    return val === 'true' || val === 'on' || val === '';
  }

  if (typeof val === 'number') {
    return val === 1;
  }

  return !!val;
}

export function convertToInt(value: any, valueDefault?: any): number {
  const validNumber = parseInt(value, 10);
  const validDefaultValue = parseInt(valueDefault, 10);
  const defaultValue = validDefaultValue || validDefaultValue === 0 ? validDefaultValue : undefined;

  return validNumber || validNumber === 0 ? validNumber : defaultValue;
}

export function isTypeof(object: any, type: any) {
  return typeof object === type;
}

/**
 *
 * @param fn Função que será executada dentro do contexto. Podendo ser o nome da função
 * ou a referência da mesma.
 *
 * @param context Contexto do qual a função será executada.
 */
export function callFunction(fn: any, context: any, param?): void {
  if (isTypeof(fn, 'function')) {
    fn.call(context, param);
  } else {
    context[fn](param);
  }
}

export function convertIsoToDate(value: string, start: boolean, end: boolean) {
  if (value) {
    const day = parseInt(value.substring(8, 10), 10);
    const month = parseInt(value.substring(5, 7), 10);
    const year = parseInt(value.substring(0, 4), 10);
    if (start) {
      const date = new Date(year, month - 1, day, 0, 0, 0);

      setYearFrom0To100(date, year);

      return date;
    } else if (end) {
      const date = new Date(year, month - 1, day, 23, 59, 59);

      setYearFrom0To100(date, year);

      return date;
    } else {
      const milliseconds = Date.parse(value);
      const timezone = new Date().getTimezoneOffset() * 60000;
      return new Date(milliseconds + timezone);
    }
  }
  return null
}

export function convertDateToISODate(date: Date) {
  if (date) {
    const getMonth = date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = getMonth < 10 ? '0' + getMonth : getMonth;
    const year = formatYear(date.getFullYear());

    return year + '-' + month + '-' + day;
  } else {
    return null;
  }
}

export function convertDateToISOExtended(date: Date, time?: string) {
  if (date) {
    const getMonth = date.getMonth() + 1;
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = getMonth < 10 ? '0' + getMonth : getMonth;
    const year = formatYear(date.getFullYear());

    const dateString = date.toString();

    if (time !== null) {
      return year + '-' + month + '-' + day + time;
    } else {
      return (
        year +
        '-' +
        month +
        '-' +
        day +
        'T' +
        dateString.substring(16, 24) +
        dateString.substring(28, 31) +
        ':' +
        dateString.substring(31, 33)
      );
    }
  } else {
    return null;
  }
}

/**
 * Transforma o ano em uma string no formato yyyy e caso o ano seja menor que 1000 preenche com zeros a esquerda.
 * @param year Ano
 */
export function formatYear(year: number) {
  if (year >= 1000) {
    return year.toString();
  }

  if (year > 99 && year < 1000) {
    return `0${year}`;
  }

  if (year > 9 && year < 100) {
    return `00${year}`;
  }

  if (year >= 0 && year < 10) {
    return `000${year}`;
  }

  return null
}
// Verifica se o navegador em que está sendo usado é Internet Explorer ou Edge
export function isIEOrEdge() {
  const userAgent = window.navigator.userAgent;

  return /msie\s|trident\/|edge\//i.test(userAgent);
}

// Verifica qual o dispositivo que está sendo usado
export function isMobile() {
  const userAgent = window.navigator.userAgent;

  return userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i);
}

export function isEquals(value, comparedValue) {
  return JSON.stringify(value) === JSON.stringify(comparedValue);
}

export function isKeyCodeEnter(event: any): boolean {
  return event.keyCode === 13 || event.which === 13;
}

/**
 * Caso o ano original da data seja entre 0 e 100 atribui esse valor ao ano, pois o `new Date` do javascript transforma o ano para 190X.
 * @param date Data
 * @param year Ano original
 */
export function setYearFrom0To100(date: Date, year: number) {
  if (year >= 0 && year < 100) {
    date.setFullYear(year);
  }
}

export function sortOptionsByProperty(options: Array<any>, property: string) {
  options.sort((optionA, optionB) => {
    optionA = optionA[property].toString().toLowerCase();
    optionB = optionB[property].toString().toLowerCase();

    if (optionA < optionB) {
      return -1;
    }
    if (optionA > optionB) {
      return 1;
    }
    return 0;
  });
}

export function removeDuplicatedOptions(list: Array<any>) {
  for (let i = 0; i < list.length; i++) {
    if (i === 0) {
      continue;
    }

    if (list.findIndex(op => op.value === list[i].value) !== i) {
      list.splice(i, 1);
      i--;
    }
  }
}

export function removeUndefinedAndNullOptions(list: Array<any>) {
  for (let i = 0; i < list.length; i++) {
    if (list[i].value === undefined || list[i].value === null) {
      list.splice(i, 1);
      i--;
    }
  }
}

export function validValue(value: any) {
  return (value !== null && value !== undefined && value !== '') || value === false;
}

export function isExternalLink(url): boolean {
  return url ? url.startsWith('http') : false;
}

export function openExternalLink(url): void {
  window.open(url, '_blank');
}

export function getFormattedLink(link: string): string {
  let formattedLink = '';
  // Retira todos os pontos no começo da URL.
  if (link) {
    formattedLink = link.replace(/^(\.)+/g, '');
  }
  // Verifica se foi utilizado uma rota que não comece com barra.
  if (!formattedLink.startsWith('/')) {
    formattedLink = '/'.concat(formattedLink);
  }
  return formattedLink;
}

/**
 * Método responsável por ordenar dois valores.
 *
 * @param leftSide Primeiro valor a ser comparado.
 * @param rightSide Segundo valor a ser comparado.
 * @param ascending Determina se será em ordem ascendente ou descendente.
 */
export function sortValues(leftSide: string, rightSide: string, ascending = true): number {
  if (ascending) {
    if (leftSide < rightSide) {
      return -1;
    } else if (leftSide > rightSide) {
      return 1;
    }
  } else if (ascending === false) {
    if (leftSide < rightSide) {
      return 1;
    } else if (leftSide > rightSide) {
      return -1;
    }
  }
  return 0;
}

export function validateDateRange(date: Date, dateStart: Date, dateEnd: Date) {
  if (dateStart && dateEnd) {
    return date >= dateStart && date <= dateEnd;
  } else if (dateStart && !dateEnd) {
    return date >= dateStart;
  } else if (!dateStart && dateEnd) {
    return date <= dateEnd;
  } else {
    return true;
  }
}

export function uuid() {
  function hex4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (
    hex4() + hex4() + '-' + hex4() + '-' + hex4() + '-' + hex4() + '-' + hex4() + hex4() + hex4()
  );
}

export function capitalizeFirstLetter(text: string): string {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

/**
 * Mapeia um novo array apenas com as propriedades definidas pelo desenvolvedor baseado em um array de
 * origem.
 *
 * Exemplo:
 *
 * ```
 * const people = [
 *  { id: 1, name: 'Fulano', birthdate: '1980-11-01', genre: 'Male', city: 'São Paulo', dependents: 2 },
 *  { id: 2, name: 'Beltrano', birthdate: '1997-01-21', genre: 'Female', city: 'Joinville', dependents: 0 },
 *  { id: 3, name: 'Siclano', birthdate: '1995-07-15', genre: 'Male', city: 'Joinville', dependents: 0 }
 * ];
 *
 * const properties = ['id', 'name'];
 *
 * const idAndName = mapArrayByProperties(people, properties);
 *
 * console.log(idAndName); // [{ id: 1, name: 'Fulano' }, { id: 2, name: 'Beltrano' }, { id: 3, name: 'Siclano' }]
 * ```
 *
 * Um outro uso para o método é "parear" todos os objetos do array com as mesmas propriedades.
 *
 * ```
 * const customers = [
 *  { id: 1, name: 'Fulano', city: 'São Paulo', dependents: 2 }, // sem genre
 *  { id: 2, name: 'Beltrano', genre: 'Female', city: 'Joinville' }, // sem dependents
 *  { id: 3, name: 'Siclano', genre: 'Male', city: 'Joinville', dependents: 0 }
 * ];
 * const properties = ['id', 'name', 'city', 'genre', 'dependents'];
 *
 * const pattern = mapArrayByProperties(customers, properties);
 * console.log(pattern);
 *
 * // [
 * //   { id: 1, name: 'Fulano', city: 'São Paulo', genre: undefined, dependents: 2 },
 * //   { id: 2, name: 'Beltrano', city: 'Joinville', genre: 'Female', dependents: undefined },
 * //   { id: 3, name: 'Siclano', city: 'Joinville', genre: 'Male', dependents: 0 }
 * // ]
 * ```
 *
 * @param items {Array<any>} Array de items original.
 * @param properties {Array<string>} Array de string com a lista de propriedades que devem ser retornadas.
 *
 * @returns Array<any>
 */
export function mapArrayByProperties(
  items: Array<any> = [],
  properties: Array<string> = []
): Array<any> {
  return items.map(item => mapObjectByProperties(item, properties));
}

/**
 * Mapeia um novo objeto apenas com as propriedades definidas pelo desenvolvedor.
 *
 * Exemplo:
 *
 * ```
 * const person = { id: 1, name: 'Fulano', birthdate: '1980-11-01', genre: 'Male', city: 'São Paulo', dependents: 2 };
 *
 * const properties = ['id', 'name'];
 *
 * const idAndName = mapObjectByProperties(person, properties);
 *
 * console.log(idAndName); // { id: 1, name: 'Fulano' }
 * ```
 *
 * @param object {Array<any>} Array de items original.
 * @param properties {Array<string>} Array de string com a lista de propriedades que devem ser retornadas.
 *
 * @returns Array<any>
 */
export function mapObjectByProperties(object: any = {}, properties: Array<string> = []) {
  const getSelectedProperties = (selectedProperties, property) => ({
    ...selectedProperties,
    [property]: object[property],
  });

  return properties.reduce(getSelectedProperties, {});
}

/**
 * Retorna os valores de um objeto dentro de um array.
 *
 * > Simula o Object.values(obj), o mesmo deve ser removido assim que a versão typescrit for atualizada.
 *
 * @param object Objeto de onde será pego os valores.
 */
export function valuesFromObject(object: any = {}): Array<any> {
  return Object.keys(object).map(property => object[property]);
}

export function getMultiselectDiff(original: string[], changed: string[]) {
  const created = changed?.filter(item => !original.includes(item));
  const removed = original.filter(item => !changed.includes(item));
  const unchanged = original.filter(item => changed.includes(item));

  return { created, removed, unchanged };
}

export function getListDiff(original: any[], changed: any[], property: string) {
  const created = changed.filter(
    item => !original.some(originalItem => item[property] === originalItem[property])
  );
  const removed = original.filter(
    item => !changed.some(changedItem => item[property] === changedItem[property])
  );
  const unchanged = original.filter(item =>
    changed.some(changedItem => item[property] === changedItem[property])
  );

  return { created, removed, unchanged };
}

export function fuzzySearch<T>(needle: string, haystack: T[], keys: string[]): T[] {
  const fuseOptions: any = {
    includeMatches: true,
    threshold: 0.3,
    sort: true,
    keys: keys,
  };

  const fuse = new Fuse(haystack, fuseOptions);
  return needle ? fuse.search(needle).map(result => result.item) : haystack;
}

export function deleteEmptyAttributes(obj: any) {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      deleteEmptyAttributes(obj[key]);
    }
    if (isEmptyInputValue(obj[key])) {
      delete obj[key];
    }
  });
}

export function markFormAsDirty(form: FormGroup) {
  Object.keys(form.controls).forEach(key => {
    markControlAsDirty(form.controls[key]);
  });
}

export function getFormValidationErrors(form) {
  const errors = [];

  Object.keys(form.controls).forEach(key => {
    const controlErrors: ValidationErrors = form.get(key).errors;
    if (controlErrors !== null) {
      Object.keys(controlErrors).forEach(keyError => {
        errors.push({
          key: key,
          keyError: keyError,
        });
      });
    }
  });

  return errors;
}

export function markControlAsDirty(control: AbstractControl) {
  control.markAsDirty();

  if (control instanceof FormGroup) {
    markFormAsDirty(control);
  } else if (control instanceof FormArray) {
    control.controls.forEach(element => markControlAsDirty(element));
  }
}

export function markFormAsPristine(form: FormGroup) {
  Object.keys(form.controls).forEach(key => {
    markControlAsPristine(form.controls[key]);
  });
}

export function markControlAsPristine(control: AbstractControl) {
  control.markAsPristine();

  if (control instanceof FormGroup) {
    markFormAsPristine(control);
  } else if (control instanceof FormArray) {
    control.controls.forEach(element => markControlAsPristine(element));
  }
}

export function mapPoSelectOptions(
  array: any[],
  value: string = 'id',
  label: string = 'label',
  icon: string = 'icon'
) {
  return array
    ? array.map(arrayItem => {
        return {
          value: arrayItem[value],
          label: arrayItem[label],
          icon: arrayItem[icon],
          data: arrayItem,
        };
      })
    : [];
}

export function setColumnLabels(
  columns: PoTableColumn[],
  labels: PoTableColumnLabel[],
  column: string
): PoTableColumn[] {
  return columns.map(c => {
    if (c.property !== column) return c;

    return { ...c, labels: labels };
  });
}

export function mapEntitiesToArray(entities) {
  return Object.keys(entities).map(id => entities[id]);
}

export function mapEntitiesToSelectOptions(entities) {
  return Object.keys(entities).map(id => {
    return {
      value: id,
      label: entities[id],
    };
  });
}

export function isEmptyInputValue(value) {
  return value === undefined || value === null || value.length === 0;
}

export function lowercaseFirstLetter(str: string): string {
  return str.replace(str[0], str[0].toLowerCase());
}

export function removeEmptyAttributes(object: any) {
  if (!object) return object;
  const obj = { ...object };

  Object.keys(obj).forEach(key => (obj[key] === '' || obj[key] === null) && delete obj[key]);
  return obj;
}

export function readFile(file: PoUploadFile): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = error => reject(error);
    reader.readAsDataURL(file.rawFile);
  });
}

export function mapEnumToKeyArray(enumElement, keyTranslate: string) {
  return Object.keys(enumElement)
    .filter(value => isNaN(Number(value)) === true)
    .map(key => ({
      value: enumElement[key],
      label: translate(keyTranslate + '.' + enumElement[key]),
    }));
}
/**
 * Adicionar breadcrumb pra Novo/Editar, quando não há container manipulando,
 * por ter mais de um form no mesmo escopo.
 */
export function setBreadcrumb(breadcrumb: PoBreadcrumb, newItem: PoBreadcrumbItem): void {
  breadcrumb.items.push({
    link: newItem.link,
    label: translate(newItem.label),
  });
}

export enum colorActive {
  active = 'po-color-10',
  inactive = 'po-color-07',
}

export interface SortColumn {
  column: PoTableColumn;
  type: PoTableColumnSortType;
}
