import { Component, Input, forwardRef } from '@angular/core';

import { PoButtonGroupItem } from '@po-ui/ng-components';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ni-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonGroupComponent),
      multi: true,
    },
  ],
})
export class ButtonGroupComponent implements ControlValueAccessor {
  private _buttonGroupOptions: Array<PoButtonGroupItem> = [];

  public disabled: boolean;

  @Input() toggle = '';
  @Input() small = false;
  @Input() required = false;

  private selectedButton: string;

  @Input()
  set buttonGroupOptions(values: Array<any>) {
    this._buttonGroupOptions = values.map(value => {
      return {
        value: value.id || value.value,
        label: value.label || '',
        icon: value.icon || '',
        tooltip: value.tooltip || '',
        disabled: value.disabled || false,
        selected: this.selectedButton === value.value,
        action: value.action || (() => this.writeValue(value.value)),
      };
    });
  }

  get buttonGroupOptions(): Array<any> {
    return this._buttonGroupOptions;
  }

  public onChange = (value: any) => {};
  public onTouched = () => {};

  constructor() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.selectedButton = obj;
    this.buttonGroupOptions = [...this.buttonGroupOptions];
    this.onChange(obj);
  }
}
