import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Injector,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { PopoverComponent } from './popover.component';
import { convertToBoolean } from '../../utils/util';

@Directive({
  selector: '[niPopover]',
})
export class PopoverDirective {
  @Input() niPopover: TemplateRef<any>;
  @Input() hideArrow: boolean = false;
  @Input() position: string = 'right';
  @Input() title: string;
  @Input() trigger: string = 'click';

  private _padding: boolean = true;

  get padding(): boolean {
    return this._padding;
  }

  @Input()
  set padding(value: boolean) {
    this._padding = convertToBoolean(value);
  }

  public popoverRef: ComponentRef<PopoverComponent>;

  private initialized: boolean;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef
  ) {}

  @HostListener('click')
  onClick() {
    // Only runs once. PoPopoverComponent manages click when created;
    if (!this.initialized && this.trigger === 'click') {
      this.createPopover();
    }
  }

  @HostListener('mouseover')
  onHover() {
    // Only runs once. PoPopoverComponent manages click when created;
    if (!this.initialized && this.trigger === 'hover') {
      this.createPopover();
    }
  }

  close() {
    if (this.popoverRef) this.popoverRef.instance.close();
  }

  private createPopover() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      PopoverComponent
    );

    const componentRef = this.viewContainerRef.createComponent(componentFactory);

    const container = componentRef.instance;
    container.padding = this.padding;
    container.popover.target = this.elementRef;
    container.popover.hideArrow = this.hideArrow;
    container.popover.position = this.position;
    container.popover.title = this.title;
    container.popover.trigger = this.trigger;
    container.popover.targetElement = this.elementRef?.nativeElement;

    container.content = this.niPopover;
    container.popover.togglePopup(this.elementRef);

    this.popoverRef = componentRef;
    this.initialized = true;
  }
}
