import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import * as AppActions from './app.actions';
import { AppEntity } from './app.models';
import { PoMenuItem } from '@po-ui/ng-components';
import { CompanySimplified } from '@recriar/mihmo-interfaces';

export const APP_FEATURE_KEY = 'app';

export interface AppState extends EntityState<AppEntity> {
  selectedId?: string | number; // which App record has been selected
  loaded: boolean; // has the App list been loaded
  loading: { loading: boolean; message?: string }; // Should display a loading spinner
  error?: string | null; // last none error (if any)
  company: CompanySimplified;
  menu: PoMenuItem[];
  bacen: string;
  conditionsShared: any;
}

export interface AppPartialState {
  readonly [APP_FEATURE_KEY]: AppState;
}

export const appAdapter: EntityAdapter<AppEntity> = createEntityAdapter<AppEntity>();

export const initialState: AppState = appAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  loading: { loading: false },
  company: null,
  menu: [],
  bacen: '',
  conditionsShared: [],
});

const appReducer = createReducer(
  initialState,
  on(AppActions.loadApp, state => ({ ...state, loaded: false, error: null })),
  on(AppActions.loadAppSuccess, (state, { app }) =>
    appAdapter.setAll(app, { ...state, loaded: true })
  ),
  on(AppActions.changeCompany, (state, { company }) => ({ ...state, company })),
  on(AppActions.loadMenuSuccess, (state, { menu }) => ({ ...state, menu })),
  on(AppActions.changeCountry, (state, { bacen }) => ({ ...state, bacen })),
  on(AppActions.loadCountrySuccess, (state, { bacen }) => ({
    ...state,
    countrySelected: bacen,
  })),
  on(AppActions.loadAppFailure, (state, { error }) => ({ ...state, error })),
  on(AppActions.loading, (state, loading) => ({ ...state, loading })),
  on(AppActions.changeConditions, (state, { conditionsShared }) =>
    appAdapter.setAll(conditionsShared, {
      ...state,
      conditionsShared,
    })
  )
);

export function reducer(state: AppState | undefined, action: Action) {
  return appReducer(state, action);
}
