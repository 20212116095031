import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, Subject } from 'rxjs';
import { UserToken } from '../models/user-token';
import { UserModel } from '../models';
import { HttpService } from '@recriar/titan';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly KEY_USER = 'mihmo-admin.user';
  private jwtHelperService: JwtHelperService;
  private userLocalStorageChange = new Subject<UserModel>();

  constructor(private http: HttpService) {
    this.jwtHelperService = new JwtHelperService();
  }

  public createUserFromToken(token: string): UserModel {
    const userToken: UserToken = this.jwtHelperService.decodeToken(token);
    return {
      userId: userToken.user_id,
      picture: userToken.picture,
      email: userToken.email,
      name: userToken.name,
      lang: 'pt-BR',
    };
  }

  public setUser(user: UserModel) {
    this.setInStorage(user);
    this.userLocalStorageChange.next(user);
  }

  public getUserLogged(): UserModel {
    return this.getInStorage();
  }

  private getInStorage(): UserModel {
    return JSON.parse(localStorage.getItem(this.KEY_USER));
  }

  private setInStorage(user: UserModel) {
    localStorage.setItem(this.KEY_USER, JSON.stringify(user));
  }

  public requestPasswordChange(email: string, api: string): Observable<{}> {
    return this.http.disableApiPrefix().post(`${api}/usuarioSistema/resetPassword/${email}`, email);
  }
}
