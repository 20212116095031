import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  HostListener,
  HostBinding,
} from '@angular/core';

@Component({
  selector: 'ni-tab-button',
  template: `
    <div
      #tab
      [ngClass]="{ 'ni-tab-size1': !small, 'ni-tab-size2': small }"
      class="po-font-text-bold flex-row-reverse"
      [p-tooltip]="label"
    >
      {{ label }}
      <i class="po-icon {{ icon }} icon-label"></i>
    </div>
  `,
  styleUrls: ['./tab-button.component.scss'],
})
export class TabButtonComponent {
  @Input() id: string;
  @Input() label: string;
  @Input() icon: string;
  @Input() small: boolean;
  @Input()
  @HostBinding('class.ni-tab-active')
  active: boolean;
  @Input()
  @HostBinding('class.ni-tab-disabled')
  disabled: boolean;
  @Input()
  @HostBinding('class.hidden')
  hide: boolean;

  // Método recebido do usuário para ser disparado quando clicar na aba
  @Output() clickCallback = new EventEmitter();

  @Output() clickTab = new EventEmitter();

  @ViewChild('tab') tab: ElementRef;

  @HostListener('click', ['$event'])
  onClick(e) {
    if (!this.disabled) {
      this.clickTab.emit(this.id);
      this.clickCallback.emit(this.id);
    }
  }

  constructor(private el: ElementRef) {}
}
