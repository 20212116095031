<div class="d-flex">
  <ni-badge *ngIf="row[column.property]?.length > 0" color="table-badge">
    {{ row[column.property][0] }}
  </ni-badge>

  <ni-badge *ngIf="row[column.property]?.length > 1" color="table-badge">
    {{ row[column.property][1] }}
  </ni-badge>

  <ni-badge *ngIf="row[column.property]?.length === 3" color="table-badge">
    {{ row[column.property][2] }}
  </ni-badge>

  <ni-badge
    *ngIf="row[column.property]?.length > 3"
    color="table-badge"
    class="po-clickable"
    [niPopover]="badgePopover"
    position="bottom"
    (click)="selectBadgeList(row[column.property])"
  >
    +{{ row[column.property]?.length - 2 }}
  </ni-badge>
</div>

<ng-template #badgePopover>
  <span *ngFor="let item of badgeListSelected; let last = last">
    {{ item + (last ? '' : ',') }}
  </span>
</ng-template>
