import { ErrorHandler } from '@angular/core';

export class TitanErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    console.error(error);

    if ((<any>window).newrelic) {
      (<any>window).newrelic.noticeError(error);
    }
  }
}
