import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectableDirective } from './selectable/selectable.directive';
import { ObserveSizeDirective } from './observe-size/observe-size.directive';
import { HasPermissionDirective } from './hasPermission/hasPermission.directive';

const MODULES = [];
const DIRECTIVES = [
  SelectableDirective,
  ObserveSizeDirective,
  HasPermissionDirective
];

@NgModule({
  imports: [CommonModule, ...MODULES],
  declarations: [...DIRECTIVES],
  exports: [...MODULES, ...DIRECTIVES],
})
export class DirectivesModule {}
