import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent, ListContentContainerComponent } from './list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ListItemComponent } from './list-item/list-item.component';
import { PoDividerModule } from '@po-ui/ng-components';

@NgModule({
  declarations: [
    ListComponent,
    ListContentContainerComponent,
    ListItemComponent,
  ],
  imports: [CommonModule, DragDropModule, PoDividerModule],
  exports: [
    ListComponent,
    ListContentContainerComponent,
    ListItemComponent,
    DragDropModule,
  ],
})
export class ListModule {}
