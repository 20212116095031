<div class="po-md-12">
  <h3 class="po-pt-1 po-pb-1 font-color-default">{{ userSelected?.name }}</h3>

  <po-info [p-label]="'CPF' | transloco" [p-value]="userSelected?.cpf"></po-info>

  <po-info [p-label]="'Empresa' | transloco" [p-value]="userSelected?.company?.name"></po-info>

  <po-info [p-label]="'E-mail' | transloco" [p-value]="userSelected?.email"></po-info>
  <div class="d-flex">
    <po-info
      class="col-6"
      [p-label]="'Celular' | transloco"
      [p-value]="userSelected?.cellphone"
    ></po-info>
    <po-info
      class="col-6"
      [p-label]="'Telefone' | transloco"
      [p-value]="userSelected?.telephone"
    ></po-info>
  </div>

  <div class="d-flex">
    <po-info class="col-6" [p-label]="'Idade' | transloco" [p-value]="userSelected?.age"></po-info>
    <po-info
      class="col-6"
      [p-label]="'Sexo' | transloco"
      [p-value]="'common.' + userSelected?.gender?.toLowerCase() | transloco"
    ></po-info>
  </div>
</div>

<po-divider class="po-md-12"></po-divider>

<ng-container *ngIf="userSelected?.teamSquadList">
  <div class="po-md-12 po-p-0">
    <div class="po-md-6 font-weight-bold">{{ 'Squad' }}</div>
    <div
      (click)="openUserResponsibleModal()"
      class="po-md-6 po-pb-2 po-text-link"
    >
      {{ 'Alterar Time de Saúde' }}
    </div>
  </div>
  <ng-container *ngFor="let memberSquad of userSelected.teamSquadList">
    <div class="po-md-6 position-relative">
      <span
        *ngIf="memberSquad.isResponsible"
        class="star responsible-star po-icon po-icon-star-filled"
      ></span>
      <span
        *ngIf="memberSquad.isAssistant"
        class="star assistant-star po-icon po-icon-star-filled"
      ></span>
      <div class="">{{ memberSquad?.name }}</div>
    </div>
    <div class="po-md-6 d-flex justify-content-between">
      <span>{{ memberSquad.roleName }}</span>
      <span *ngIf="memberSquad.calendarLink" (click)="openCalendly(memberSquad)" class="po-icon po-icon-calendar custom-icon-calendar cursor-pointer po-ml-1"></span>
    </div>
  </ng-container>
</ng-container>
