<div class="po-table-footer-show-subtitle" (click)="modal.open()">
  <div class="po-table-footer-show-subtitle-container">
    <span class="po-table-show-subtitle">{{
      literals.seeCompleteSubtitle
    }}</span>
  </div>
</div>

<po-modal #modal p-size="sm" [p-title]="literals.completeSubtitle">
  <div
    *ngFor="let subtitle of subtitles"
    class="po-table-subtitle-footer-modal po-row"
  >
    <ni-table-subtitle-circle [p-hide-title]="true" [p-subtitle]="subtitle">
    </ni-table-subtitle-circle>
    <span class="po-table-subtitle-text">{{ subtitle.label }}</span>
  </div>
</po-modal>
