import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TableColumn } from '../interfaces/table-column.interface';

/**
 * @docsPrivate
 *
 * @description
 *
 * Componente responsável por exibir link nas colunas.
 */
@Component({
  selector: 'ni-table-column-badge',
  templateUrl: './table-column-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableColumnBadgeComponent {
  @Input('p-row') row;

  @Input('p-column') column: TableColumn;

  badgeListSelected = [];

  selectBadgeList(badgeList: any[]) {
    if (badgeList && badgeList.length) {
      this.badgeListSelected = [...badgeList];

      // Remove os dois primeiros elementos do array
      this.badgeListSelected.shift();
      this.badgeListSelected.shift();
    }
  }
}
