<div class="ni-list po-pt-1" [ngClass]="{ draggable: draggable }">
  <ng-content select="ni-list-header"></ng-content>
  <ng-content select="ni-list-content"></ng-content>

  <ni-list-content *ngIf="itemTemplate">
    <div
      *ngIf="draggable; else defaultList"
      cdkDropList
      (cdkDropListDropped)="dropListDropped.emit($event)"
      (cdkDropListSorted)="dropListSorted.emit($event)"
    >
      <ng-container *ngFor="let item of items; let i = index">
        <ni-list-item cdkDrag>
          <span [style.fontSize.px]="24" class="po-icon po-icon-menu"></span>

          <ng-container
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{ item: item, index: i }"
          ></ng-container>
        </ni-list-item>
      </ng-container>
    </div>

    <!-- "else" necessary because I can't apply directives dynamically -->
    <ng-template #defaultList>
      <ng-container *ngFor="let item of items; let i = index">
        <ni-list-item [border]="listItemBorder" class="d-flex align-items-center justify-content-start">
          <ng-container
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{ item: item, index: i }"
          ></ng-container>
        </ni-list-item>
      </ng-container>
    </ng-template>
  </ni-list-content>
</div>
