<div class="po-page-header">
  <!-- Breadcrumb -->
  <div
    class="po-page-header-breadcrumb"
    *ngIf="breadcrumb && breadcrumb.items.length"
  >
    <po-breadcrumb
      [p-favorite-service]="breadcrumb.favorite"
      [p-items]="breadcrumb.items"
      [p-params-service]="breadcrumb.params"
    >
    </po-breadcrumb>
  </div>

  <!-- Titulo -->
  <h1 class="po-page-header-title" *ngIf="title">
    <img
      *ngIf="backUrl"
      src="assets/icons/back-primary.svg"
      alt="Back"
      class="po-clickable"
      [routerLink]="backUrl"
    />

    <span *ngIf="title"> {{ title }} </span>
  </h1>

  <!-- Operações -->
  <ng-content></ng-content>
</div>
