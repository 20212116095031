import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChange,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';

import { callFunction, convertToBoolean, isTypeof } from '../../../utils/util';
import { PageAction } from '../page-action.interface';

import { PageContentComponent } from '../page-content/page-content.component';
import { PoBreadcrumb, PoButtonComponent } from '@po-ui/ng-components';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PushNotification } from '../page.interface';

@Component({
  selector: 'ni-page-default',
  templateUrl: './page-default.component.html',
  styleUrls: ['./page-default.component.scss'],
})
export class PageDefaultComponent implements AfterContentInit, OnChanges, OnInit, OnDestroy {
  private _actions?: Array<PageAction> = [];
  private _title: string;
  private _enableSearch: boolean;

  @ViewChild(PoButtonComponent, { read: ElementRef, static: true }) poButton: PoButtonComponent;

  @ViewChild(PageContentComponent, { static: true })
  pageContent: PageContentComponent;

  /**
   * @optional
   *
   * @description
   *
   * Nesta propriedade deve ser definido um array de objetos que implementam a interface `PageAction`.
   */
  @Input('p-actions') set actions(actions: Array<PageAction>) {
    this._actions = Array.isArray(actions) ? actions : [];
    this.setDropdownActions();
  }

  get actions(): Array<PageAction> {
    return this._actions;
  }

  /** Objeto com propriedades do breadcrumb. */
  @Input('p-breadcrumb') breadcrumb?: PoBreadcrumb;

  /** Título da página. */
  @Input('p-title') set title(title: string) {
    this._title = title;
    this.pageContent.recalculateHeaderSize();
  }

  get title() {
    return this._title;
  }

  @Input('p-enable-search') set enableSearch(value: boolean) {
    this._enableSearch = convertToBoolean(value);
  }

  get enableSearch(): boolean {
    return this._enableSearch;
  }

  @Input('p-back-url') backUrl: any[] | string;

  @Input('userSelected') userSelected: any;

  @Input('userLogged') userLogged: any;

  @Input('showQuizInitial') showQuizInitial = false;

  @Input('showHeaderWithAdditionInfo') showHeaderWithAdditionInfo = false;

  @Input('limesurveyList') limesurveyList = [];

  @Input() loading = false;

  @Output() userListModal = new EventEmitter<void>();

  @Output() userResponsibleModal = new EventEmitter<void>();

  @Output() fetchSquadSelectedPopover = new EventEmitter<void>();

  @Output() userInfo = new EventEmitter<any>();

  @Output() search = new EventEmitter<string>();

  @Output() sendPushNotification = new EventEmitter<PushNotification>();

  @Output() openCalendlyLink = new EventEmitter<string>();

  limitPrimaryActions: number = 3;
  dropdownActions: Array<PageAction>;
  isMobile: boolean;
  searchChanged$: Subject<string> = new Subject<string>();
  searchChangedSubscription: Subscription;

  private maxWidthMobile: number = 480;
  private parentRef: ViewContainerRef;

  constructor(viewRef: ViewContainerRef, private renderer: Renderer2, private router: Router) {
    this.parentRef = viewRef['_hostLView'][8];
  }

  public ngAfterContentInit(): void {
    this.setIsMobile();
    this.setDropdownActions();

    this.renderer.listen('window', 'resize', (event: Event) => {
      this.onResize(event);
    });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    this.setDropdownActions();
  }

  ngOnInit(): void {
    this.searchChangedSubscription = this.searchChanged$
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((term: string) => this.search.emit(term));
  }

  ngOnDestroy(): void {
    this.searchChangedSubscription.unsubscribe();
  }

  actionIsDisabled(action: any) {
    return isTypeof(action.disabled, 'function') ? action.disabled(action) : action.disabled;
  }

  callAction(item: PageAction): void {
    if (item.url) {
      this.router.navigate([item.url]);
    } else if (item.action) {
      callFunction(item.action, this.parentRef);
    }
  }

  hasPageHeader() {
    return !!(
      this.title ||
      (this.actions && this.actions.length) ||
      (this.breadcrumb && this.breadcrumb.items.length)
    );
  }

  emitSearch(term: string) {
    this.search.emit(term);
  }

  private onResize(event: Event): void {
    const width = (event.target as Window).innerWidth;

    if (width < this.maxWidthMobile) {
      this.isMobile = true;
      this.limitPrimaryActions = 2;
      this.setDropdownActions();
    } else {
      this.isMobile = false;
      this.limitPrimaryActions = 3;
      this.setDropdownActions();
    }
  }

  private setIsMobile(): void {
    if (window.innerWidth < this.maxWidthMobile) {
      this.isMobile = true;
      this.limitPrimaryActions = 2;
    }
  }

  setDropdownActions(): void {
    if (this.actions.length > this.limitPrimaryActions) {
      this.dropdownActions = this.actions.slice(this.limitPrimaryActions - 1);
    }
  }

  public openUserListModal() {
    this.userListModal.emit();
  }

  public openUserResponsibleModal() {
    this.userResponsibleModal.emit();
  }

  openPopover() {
    this.fetchSquadSelectedPopover.emit();
  }

  emitSendPushNotification($event: PushNotification): void {
    this.sendPushNotification.emit($event);
  }

  emitCalendlyLink($event: string): void {
    this.openCalendlyLink.emit($event);
  }
}
