import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable()
export class IconService {
  private requests = new Map<string, Promise<any>>();

  getSvgContent(url: string): Observable<string> {
    // see if we already have a request for this url
    let req = this.requests.get(url);

    if (!req) {
      // we don't already have a request
      req = fetch(url).then((rsp) => {
        if (rsp.ok) {
          return rsp.text();
        }
      });

      // cache for the same requests
      this.requests.set(url, req);
    }

    return fromPromise(req);
  }
}
