export interface Literals {
  welcome: string;
  appName: string;
  appLastName?: string;
  loginPlaceholder: string;
  passwordPlaceholder: string;
  rememberUserLabel: string;
  accessRecover?: string;
  enterYourEmail: string;
  recoverPasswordByEmail: string;
  requestPasswordChange: string;
  contactSupport: string;
  enter: string;
  version: string;
  send: string;
  cancel: string;
  api: string;
}
