import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxGroupComponent } from './checkbox-group.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {PoFieldContainerModule, PoFieldModule} from '@po-ui/ng-components';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, PoFieldModule, PoFieldContainerModule],
  declarations: [CheckboxGroupComponent],
  exports: [CheckboxGroupComponent],
})
export class CheckboxGroupModule {}
