import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StyleManager } from './style-manager/style-manager';
import { ThemeStorage } from './theme-storage/theme-storage';

export interface Theme {
  href: string;
  isDefault?: boolean;
}

@Component({
  selector: 'ni-theme-picker',
  template: `
    <div class="po-mr-2">
      <po-switch
        name="switch"
        [(ngModel)]="darkMode"
        (ngModelChange)="toggleMode()"
        [p-label-off]="lightLabel"
        [p-label-on]="darkLabel"
      >
      </po-switch>
    </div>
  `,
  styles: [
    `
      :host > div {
        min-width: 135px;
      }

      :host ::ng-deep .po-switch-content {
        margin-top: 0;
      }

      :host ::ng-deep .po-switch-label {
        font-size: 14px;
      }
    `,
  ],
})
export class ThemePickerComponent {
  @Input() public darkLabel: string;
  @Input() public lightLabel: string;

  @Output() darkModeOn: EventEmitter<boolean> = new EventEmitter();

  public darkMode?: boolean;
  public currentTheme: Theme;
  public themes: Theme[];

  constructor(
    private themeStorage: ThemeStorage,
    private styleManager: StyleManager
  ) {
    this.themes = [
      { href: 'light.css', isDefault: true },
      { href: 'dark.css' },
    ];

    const currentTheme = this.themeStorage.getStoredTheme() || this.themes[0];
    if (currentTheme) {
      this.installTheme(currentTheme);
    }
  }

  public toggleMode() {
    const href = this.themes[this.darkMode ? 1 : 0]; //dark mode is the second element of themes array
    this.installTheme(href);
    this.darkModeOn.emit(this.darkMode);
  }

  public installTheme(theme: Theme) {
    this.currentTheme = this.getCurrentTheme(theme.href);
    this.darkMode = !theme.isDefault;

    if (theme.isDefault) {
      this.styleManager.removeStyle('theme');
    } else {
      this.styleManager.setStyle('theme', `assets/themes/${theme.href}`);
    }

    if (this.currentTheme) {
      this.themeStorage.storeTheme(this.currentTheme);
    }
  }

  private getCurrentTheme(href: string): Theme {
    return this.themes.find(theme => theme.href === href);
  }
}
