import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { convertToBoolean } from '../../utils/util';

@Component({
  selector: 'ni-button-toolbar',
  templateUrl: './button-toolbar.component.html',
  styleUrls: ['./button-toolbar.component.scss'],
})
export class ButtonToolbarComponent {
  private _padding = true;

  get padding(): boolean {
    return this._padding;
  }

  @Input()
  @HostBinding('class.padding')
  set padding(value: boolean) {
    this._padding = convertToBoolean(value);
  }

  constructor(public el: ElementRef) {}
}
