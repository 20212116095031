import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import * as fromApp from './app.reducer';
import * as AppSelectors from './app.selectors';
import * as AppActions from './app.actions';
import { CompanySimplified } from '@recriar/mihmo-interfaces';
import { CompanyAuthService } from '@recriar/mihmo-auth';
import { Conditions } from '../features/onboarding-conditions/models/onboarding-conditions.model';

@Injectable()
export class AppFacade {
  loaded$ = this.store.pipe(select(AppSelectors.getAppLoaded));
  loading$ = this.store.pipe(select(AppSelectors.getLoading));
  allApp$ = this.store.pipe(select(AppSelectors.getAllApp));
  selectedApp$ = this.store.pipe(select(AppSelectors.getSelected));
  selectedCountry$ = this.store.pipe(select(AppSelectors.getCountrySelected));
  menu$ = this.store.pipe(select(AppSelectors.getMenu));
  conditions$ = this.store.pipe(select(AppSelectors.getConditions));

  constructor(
    private store: Store<fromApp.AppPartialState>,
    private companyAuthService: CompanyAuthService
  ) {}

  loadAll() {
    this.store.dispatch(AppActions.loadApp());
  }

  changeCompany(company: CompanySimplified) {
    this.companyAuthService.setCompanySelected(company);
    this.store.dispatch(AppActions.changeCompany({ company }));
  }

  changeCountry(bacen: string) {
    this.store.dispatch(AppActions.changeCountry({ bacen }));
  }

  changeConditions(conditionsShared: Conditions[]) {
    this.store.dispatch(AppActions.changeConditions({ conditionsShared }));
  }

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
