<po-field-container>
  <div class="po-row" [ngClass]="padding ? 'po-pt-1 po-pb-1' : ''" #inp>
    <div
      *ngFor="let option of options"
      class="po-radio-group-item po-md-{{ columns }}"
    >
      <input
        #input
        class="po-radio-group-input"
        type="radio"
        [attr.name]="name"
        [checked]="value === option.value"
        [class.po-radio-group-input-checked]="value === option.value"
        [disabled]="option.disabled === true || disabled"
        [required]="required"
        [value]="option.value"
        (keyup)="onKeyUp($event, option.value)"
        (click)="eventClick(option.value, option.disabled === true || disabled)"
      />
      <label
        class="po-radio-group-label"
        [class.po-clickable]="!input.disabled"
        [for]="name"
        (click)="eventClick(option.value, option.disabled === true || disabled)"
      >
        <ng-container *ngIf="!template">{{ option.label }}</ng-container>
        <ng-container
          [ngTemplateOutlet]="template"
          [ngTemplateOutletContext]="{ option: option }"
        ></ng-container>
      </label>
    </div>
  </div>
  <po-field-container-bottom></po-field-container-bottom>
</po-field-container>
