import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SquadUser } from '../page.interface';

@Component({
  selector: 'ni-page-information',
  templateUrl: './page-information.component.html',
  styleUrls: ['./page-information.component.scss'],
})
export class PageInformationComponent implements OnInit {
  @Input() userSelected: any;
  @Input() userLogged: any;
  @Output() userResponsibleModal = new EventEmitter<void>();
  @Output() calendlyLink = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  public openUserResponsibleModal() {
    this.userResponsibleModal.emit();
  }

  public openCalendly(memberSquad: SquadUser): void {
    this.calendlyLink.emit(memberSquad.calendarLink);
  }
}
