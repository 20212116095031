import {
  trigger,
  transition,
  style,
  animate,
  state,
} from '@angular/animations';

export const collapsible = trigger('collapsible', [
  state(
    'expanded',
    style({
      height: '*',
      'padding-top': '*',
      'padding-bottom': '*',
      opacity: '*',
      transform: '*',
    })
  ),
  state(
    'collapsed',
    style({
      height: '0',
      'padding-top': '0',
      'padding-bottom': '0',
      opacity: '0',
      transform: 'scaleY(0)',
    })
  ),
  transition('expanded => collapsed', animate('200ms ease-in')),
  transition('collapsed => expanded', animate('200ms ease-out')),
]);
