import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  AdvancedSearchFields,
  AdvancedSearchFieldsDisclaimerChange,
  AdvancedSearchGridColumns,
  AdvancedSearchTypeFields,
  CLASS_GRID,
} from './model/advanced-search.model';
import {
  PoDisclaimer,
  PoModalAction,
  PoModalComponent,
} from '@po-ui/ng-components';
import { mapPoSelectOptions } from '../../../utils/util';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'ni-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
})
export class AdvancedSearchComponent implements OnInit {
  @ViewChild('advancedSearchModal') advancedSearchModal: PoModalComponent;
  @Input() arrayFields: AdvancedSearchFields[] = [];
  @Output() filterFields = new EventEmitter<{ [key: string]: unknown }>();
  @Output() disclaimerFields = new EventEmitter<PoDisclaimer[]>();

  advancedSearchForm: FormGroup;
  typeFields = AdvancedSearchTypeFields;
  showFields: PoDisclaimer[] = [];
  fieldsWithValue = {};

  public modalActions: {
    primary: PoModalAction;
    secondary: PoModalAction;
  } = {
    primary: {
      label: translate('common.confirm'),
      action: () => this.onSubmit(this.advancedSearchForm),
    },
    secondary: {
      label: translate('common.cancel'),
      action: () => this.advancedSearchModal.close(),
    },
  };

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    const fieldsBuilder = {};
    const regexReplaceGridClassWithNumber = /gc/g;

    this.arrayFields.forEach((item) => {
      const gridColumn = item.gridColumns ?? AdvancedSearchGridColumns.Six;

      if (item.type === AdvancedSearchTypeFields.Radio) {
        item.radioOptions = [];
        item.options.forEach((option) => {
          item.radioOptions.push(option);
        });
      } else if (item.type === AdvancedSearchTypeFields.Select) {
        item.selectOptions = mapPoSelectOptions(item.options, 'value', 'label');
      } else if (item.type === AdvancedSearchTypeFields.Combo) {
        item.comboOptions = mapPoSelectOptions(item.options, item.fieldValue, item.fieldLabel);
      } else if (item.type === AdvancedSearchTypeFields.MultiSelect) {
        item.multiSelectOptions = mapPoSelectOptions(
          item.options,
          'value',
          'label'
        );
      }
      fieldsBuilder[item.formControlName] = [''];
      item.class = CLASS_GRID.replace(
        regexReplaceGridClassWithNumber,
        gridColumn.toString()
      );
      return item;
    });
    this.advancedSearchForm = this.formBuilder.group(fieldsBuilder);
  }

  onSubmit(form: FormGroup): void {
    this.showFields = [];
    this.fieldsWithValue = [];

    Object.entries(form.value).map((item) => {
      if (item[1]) {
        const field: AdvancedSearchFields = this.arrayFields.find(
          (filter) => filter.formControlName === item[0]
        );

        if (field.options && field.options?.length !== 0) {
          this.mountValueWithOptions(item, field);
          return;
        }
        this.fieldsWithValue[item[0]] = item[1];
        this.mountDisclaimer(field, item, item[1]);
      }
    });

    this.filterFields.emit(this.fieldsWithValue);
    this.advancedSearchModal.close();
  }

  filter(disclaimerChanges: AdvancedSearchFieldsDisclaimerChange[]): void {
    if (disclaimerChanges.length === 0) {
      this.resetForm();
      return;
    }

    const fieldsForm = [];
    const remainingFields = [];
    Object.entries(this.advancedSearchForm.value).forEach((item) => {
      if (item[1]) {
        fieldsForm.push(item[0]);
      }
    });

    disclaimerChanges.forEach((field) => {
      remainingFields.push(field.property);
    });

    const fieldsClearForm: string[] = fieldsForm.filter((field) => {
      if (!remainingFields.includes(field)) {
        return field;
      }
    });

    if (fieldsClearForm.length > 0) this.resetForm(fieldsClearForm);
  }

  resetForm(listFieldClear?: string[]): void {
    if (listFieldClear?.length > 0) {
      listFieldClear.map((value: string) =>
        this.advancedSearchForm.get(value).setValue('')
      );
      const fieldsWithValue = {};
      Object.entries(this.advancedSearchForm.value).map((item) => {
        if (item[1]) {
          let arrayKey = '';
          if (Array.isArray(item[1])) {
            arrayKey = '[]';
          }
          fieldsWithValue[`${item[0]}${arrayKey}`] = item[1];
        }
      });
      this.filterFields.emit(fieldsWithValue);
      return;
    }
    this.advancedSearchForm.reset();
    this.filterFields.emit({});
  }

  mountValueWithOptions(
    item: [string, unknown],
    field: AdvancedSearchFields
  ): void {
    let value = '';
    let arrayKey = '';
    if (Array.isArray(item[1])) {
      arrayKey = '[]';
    }

    this.fieldsWithValue[`${item[0]}${arrayKey}`] = item[1];

    if (Array.isArray(item[1])) {
      item[1].forEach((valueSelected) => {
        let assistant = ', ';
        if (!value) assistant = '';
        value += `${assistant}${
          field.options.find((option) => option.value === valueSelected).label
        }`;
      });
    } else {
      value = field.options.find((option) => option.value === item[1]).label;
    }
    this.mountDisclaimer(field, item, value);
  }

  mountDisclaimer(
    field: AdvancedSearchFields,
    item: [string, unknown],
    value: unknown
  ): void {
    this.showFields.push({
      label: `${field.label}: ${value}` as string,
      property: field.formControlName,
      value: item[1],
    });
    this.disclaimerFields.emit(this.showFields);
  }
}
