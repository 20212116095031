import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Observable, of } from 'rxjs';

interface ValidationError {
  control: string;
  error: string;
  value: any;
}

export class CustomValidators {
  //TODO: implementar recursão
  getFormErrors(form: FormGroup): Array<ValidationError> {
    const errors = [];
    Object.keys(form.controls).forEach((key) => {
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors !== null) {
        Object.keys(controlErrors).forEach((keyError) => {
          errors.push({
            control: key,
            error: keyError,
            value: controlErrors[keyError],
          });
        });
      }
    });
    return errors;
  }

  getFormArrayErrors(formArray: FormArray): Array<ValidationError> {
    const errors = [];
    formArray.controls.forEach((control: AbstractControl) => {
      const error = this.getFormErrors(control as FormGroup);
      if (error.length > 0) {
        error.forEach((er) => {
          errors.push(er);
        });
      }
    });
    return errors;
  }

  getValidationMessage(er: ValidationError): string {
    return 'messages.validators.' + er.error;
  }

  static requiredList(fromControl: FormControl) {
    return fromControl.value && fromControl.value.length
      ? null
      : {
          requiredList: {
            valid: false,
          },
        };
  }

  static uniqueEmailValidator(
    formArrayHolder: FormArray,
    formArrayDependents: FormArray
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (
        !(formArrayDependents instanceof FormArray) ||
        !(formArrayHolder instanceof FormArray)
      ) {
        return null;
      }

      const email = control.value;
      if (!email) {
        return null;
      }

      let existingEmails = formArrayDependents.controls
        .filter((c) => c.get('email') !== control)
        .map((c) => c.get('email')?.value)
        .filter((val) => val !== null && val !== undefined);

      const holder = formArrayHolder.controls
        .filter((c) => c.get('email') !== control)
        .map((c) => c.get('email')?.value)
        .filter((val) => val !== null && val !== undefined);

      existingEmails = [...existingEmails, ...holder];

      const exist = existingEmails.find((data) => data === email);

      return exist ? { duplicateEmail: true } : null;
    };
  }

  static uniqueRoleAsyncValidator(formGroup: FormGroup): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control || !control.value) {
        return null;
      }

      const controlName = Object.keys(formGroup.controls).find(
        (controlFromGroup) => formGroup.get(controlFromGroup) === control
      );
      const values = Object.keys(formGroup.controls)
        .filter((name) => name !== controlName)
        .map((name) => formGroup.get(name)?.value)
        .filter((value) => value);

      const hasDuplicate = values.includes(control.value);

      return hasDuplicate ? of({ duplicateRole: true }) : null;
    };
  }
}
