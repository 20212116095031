import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PoButtonModule, PoFieldModule } from '@po-ui/ng-components';
import { PoCheckboxGroupModule } from '@po-ui/ng-components';
import { PoContainerModule } from '@po-ui/ng-components';
import { PoLoadingModule } from '@po-ui/ng-components';
import { PoModalModule } from '@po-ui/ng-components';
import { PoPopoverModule } from '@po-ui/ng-components';
import { PoPopupModule } from '@po-ui/ng-components';
import { PoTimeModule } from '@po-ui/ng-components';
import { PoTooltipModule } from '@po-ui/ng-components';

import { TableColumnIconComponent } from './table-column-icon/table-column-icon.component';
import { TableColumnLabelComponent } from './table-column-label/table-column-label.component';
import { TableColumnLinkComponent } from './table-column-link/table-column-link.component';
import { TableColumnManagerComponent } from './table-column-manager/table-column-manager.component';
import { TableComponent } from './table.component';
import { TableDetailComponent } from './table-detail/table-detail.component';
import { TableIconComponent } from './table-icon/table-icon.component';
import { TableRowTemplateDirective } from './table-row-template/table-row-template.directive';
import { TableShowSubtitleComponent } from './table-show-subtitle/table-show-subtitle.component';
import { TableSubtitleCircleComponent } from './table-subtitle-circle/table-subtitle-circle.component';
import { TableSubtitleFooterComponent } from './table-subtitle-footer/table-subtitle-footer.component';
import { TableColumnTemplateDirective } from './table-column-template/table-column-template.directive';
import { TableColumnBadgeComponent } from './table-column-badge/table-column-badge.component';
import { BadgeModule } from '../badge';
import { PopoverModule } from '../popover';

/**
 * @description
 * Módulo do componente po-table
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    PoButtonModule,
    PoCheckboxGroupModule,
    PoContainerModule,
    PoLoadingModule,
    PoModalModule,
    PoPopoverModule,
    PoPopupModule,
    PoTimeModule,
    PoTooltipModule,
    BadgeModule,
    PopoverModule,
    PoFieldModule,
  ],
  declarations: [
    TableComponent,
    TableColumnBadgeComponent,
    TableColumnIconComponent,
    TableColumnLabelComponent,
    TableColumnLinkComponent,
    TableColumnManagerComponent,
    TableColumnTemplateDirective,
    TableDetailComponent,
    TableIconComponent,
    TableRowTemplateDirective,
    TableShowSubtitleComponent,
    TableSubtitleCircleComponent,
    TableSubtitleFooterComponent,
  ],
  exports: [
    TableComponent,
    TableRowTemplateDirective,
    TableColumnTemplateDirective,
    TableIconComponent,
  ],
  providers: [DecimalPipe],
})
export class TableModule {}
