import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { IconService } from './icon.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ni-icon',
  template: ` <div class="icon-inner" [innerHTML]="svgContent | async"></div> `,
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  private _size: 'small' | 'medium' | 'large' | string;

  get size(): 'small' | 'medium' | 'large' | 'icon-toolbar' | string {
    return this._size;
  }

  @Input()
  set size(value: 'small' | 'medium' | 'large' | string) {
    this._size = value ? value : ' ';
    this.setClasses();
  }

  private _width: 'default' | 'inherit' | string;
  @Input()
  set width(value: 'inherit' | 'default' | string) {
    this._width = value ? value : ' ';
    this.setClasses();
  }

  get width(): 'inherit' | 'default' | string {
    return this._width;
  }

  public classes: string;
  @HostBinding('class') get HeadingClass() {
    return this.classes;
  }

  @Input()
  public svgContent: Observable<SafeHtml>;

  private _src: string;

  get src(): string {
    return this._src;
  }

  @Input()
  set src(value: string) {
    this._src = value;
    this.setSvgContent();
  }

  constructor(private sanitizer: DomSanitizer, private iconService: IconService) {}

  private setSvgContent() {
    this.svgContent = this.iconService
      .getSvgContent(this._src)
      .pipe(map(content => this.sanitizer.bypassSecurityTrustHtml(content)));
  }

  setClasses() {
    this.classes = this._size + ' ' + this._width;
  }
}
