<div class="quick-search d-flex justify-content-end">
  <po-input
    (keyup.enter)="quickSearch(searchText)"
    [(ngModel)]="searchText"
    class="po-mr-1 po-ml-1 po-md-12"
    p-clean="{{ clean }}"
    (p-change-model)="onChange($event)"
    [p-placeholder]="'common.search' | transloco"
  ></po-input>
  <po-button
    (p-click)="quickSearch(searchText)"
    class="d-flex align-items-center"
    p-icon="po-icon po-icon-search"
  ></po-button>
</div>
