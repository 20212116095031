import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, LiteralsService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginForm } from '../../models/login-form';
import { Literals } from '../../models/literals.model';
import { Subscription } from 'rxjs';
import { RecoverPasswordComponent } from '../recover-password/recover-password.component';
import { PoNotificationService, PoToasterOrientation } from '@po-ui/ng-components';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'auth-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('recoverPassword')
  public recoverPasswordRef: RecoverPasswordComponent;
  public loginForm: FormGroup = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
    keepAlive: [true],
  });
  public literals: Partial<Literals> = {};
  public isLoading: boolean;
  private subscriptions: Array<Subscription> = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private literalsService: LiteralsService,
    private poNotificationService: PoNotificationService
  ) {
    this.literalsService.getLiterals().subscribe((literals: Literals) => {
      this.literals = literals;
    });
    this.subscriptions.push(
      this.literalsService.getLiterals().subscribe(literals => this.setLiterals(literals))
    );
  }

  ngOnInit(): void {
    const { token } = this.route.snapshot.queryParams;

    if (token) {
      this.authService.authenticateSso(token);
      this.navigateTo(this.authService.redirectUrl);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  public onSubmit({ value, valid }: { value: LoginForm; valid: boolean }) {
    if (!valid) {
      this.poNotificationService.warning({
        message: translate('common.requiredField'),
        orientation: PoToasterOrientation.Top,
      });
    }

    this.isLoading = true;

    this.authenticate(value).then(
      () => {
        this.isLoading = false;
        this.navigateTo('/home');
      },
      e => {
        this.isLoading = false;
        if (e.name === 'HttpErrorResponse') {
          return;
        }
        this.poNotificationService.warning({
          message: translate('common.invalidPassword'),
          orientation: PoToasterOrientation.Top,
        });
      }
    );
  }

  public authenticate(loginForm: LoginForm) {
    return this.authService.authenticate(loginForm);
  }

  public navigateTo(redirectUrl?: string) {
    const url = redirectUrl || '/home';
    this.router.navigateByUrl(url);
  }

  private setLiterals(literals: Literals) {
    this.literals = literals;
  }
  open() {
    this.recoverPasswordRef.openModal();
  }
}
