import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[p-table-column-template]',
})
export class TableColumnTemplateDirective {
  @Input('column') column: string;

  constructor(public templateRef: TemplateRef<any>) {}
}
