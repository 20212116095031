import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderButtonsComponent } from './slider-buttons.component';
import { PoButtonModule } from '@po-ui/ng-components';

/**
 * @description
 * Módulo do componente ni-tabs
 */
@NgModule({
  imports: [CommonModule, PoButtonModule],
  declarations: [SliderButtonsComponent],
  exports: [SliderButtonsComponent],
})
export class SliderButtonsModule {}
