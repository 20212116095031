import { Component, Input, ViewChild } from '@angular/core';
import { PoModalComponent } from '@po-ui/ng-components';

@Component({
  selector: 'ni-limesurvey-initial-quiz-modal',
  templateUrl: './limesurvey-initial-quiz-modal.component.html',
  styleUrls: ['./limesurvey-initial-quiz-modal.component.scss'],
})
export class LimesurveyInitialQuizModalComponent {
  @ViewChild('modal') modal: PoModalComponent;
  @Input() customerSelected: any;
  @Input() limesurveyList: any;

  open() {
    this.modal.open();
  }

  close() {
    this.modal.close();
  }
}
