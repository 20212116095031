import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CompanyAuthService } from './company-auth.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyGuard implements CanActivate {
  constructor(private companyAuthService: CompanyAuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    return true;
    //   if (this.companyAuthService.checkCompany()) {
    //     return true;
    //   }
    //
    //   this.companyAuthService.redirectUrl = url;
    //   this.router.navigateByUrl('/companies');
    //
    //   return false;
  }
}
