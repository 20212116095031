import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollapsibleWidgetComponent } from './collapsible-widget.component';
import { PoButtonModule, PoFieldModule } from '@po-ui/ng-components';
import { DirectivesModule } from '../../directives';
import { CollapsibleHeaderComponent } from './collapsible-header/collapsible-header.component';
import { CollapsibleBodyComponent } from './collapsible-body/collapsible-body.component';

@NgModule({
  declarations: [
    CollapsibleWidgetComponent,
    CollapsibleHeaderComponent,
    CollapsibleBodyComponent,
  ],
  imports: [CommonModule, PoFieldModule, PoButtonModule, DirectivesModule],
  exports: [
    CollapsibleWidgetComponent,
    CollapsibleHeaderComponent,
    CollapsibleBodyComponent,
  ],
})
export class CollapsibleWidgetModule {}
