import { Component, Input } from '@angular/core';

import { TableSubtitleColumn } from '../table-subtitle-footer/table-subtitle-column.interface';

/**
 * @docsPrivate
 *
 * @description
 *
 * Componente para a criação de um botão e modal para visualização de todas as legendas.
 */
@Component({
  selector: 'ni-table-show-subtitle',
  templateUrl: './table-show-subtitle.component.html',
})
export class TableShowSubtitleComponent {
  /** Objeto com os dados da legenda. */
  @Input('p-subtitles') subtitles: Array<TableSubtitleColumn>;

  /** Propriedade que recebe as literais definidas no `po-table`. */
  @Input('p-literals') literals;
}
