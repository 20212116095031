import { PoPopupAction } from '@po-ui/ng-components';

/**
 * @description
 * Interface para as ações dos componentes page-default e page-list.
 *
 * > As propriedades `selected`, `separator`, `type` e `visible` serão vistas a partir da terceira ação e somente quando
 * definir quatro ações ou mais.
 *
 * @docsExtends pupAction
 *
 * @usedBy PageDefaultComponent, PageListComponent
 */
// tslint:disable-next-line:no-empty-interface
export interface PageAction extends PoPopupAction {}
