import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiBaseUrlToken } from '../services/tokens';

@Injectable({ providedIn: 'root' })
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor(@Inject(ApiBaseUrlToken) private baseUrl: string) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.startsWith('./')) {
      request = request.clone({ url: this.baseUrl + request.url });
    }
    return next.handle(request);
  }
}
