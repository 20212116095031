import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverDirective } from './popover.directive';
import { PoPopoverModule } from '@po-ui/ng-components';
import { PopoverComponent } from './popover.component';

@NgModule({
  declarations: [PopoverDirective, PopoverComponent],
  imports: [CommonModule, PoPopoverModule],
  exports: [PopoverDirective, PopoverComponent],
})
export class PopoverModule {}
