import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { PoCheckboxGroupComponent } from '@po-ui/ng-components';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ni-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxGroupComponent),
      multi: true,
    },
  ],
})
export class CheckboxGroupComponent extends PoCheckboxGroupComponent {
  @Input('p-template') public template;

  constructor(changeDetector: ChangeDetectorRef) {
    super(changeDetector);
  }
}
