import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { PageComponent } from './page.component';
import { PageContentComponent } from './page-content/page-content.component';
import { PageDefaultComponent } from './page-default/page-default.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import {
  PoAvatarModule,
  PoBreadcrumbModule,
  PoButtonModule,
  PoDividerModule,
  PoDropdownModule,
  PoFieldModule,
  PoInfoModule,
  PoLoadingModule,
  PoModalModule,
  PoPopoverModule,
} from '@po-ui/ng-components';
import { TranslocoModule } from '@ngneat/transloco';
import { PageInformationComponent } from './page-information/page-information.component';
import { PageNotificationButtonComponent } from './page-notification-button/page-notification-button.component';
import { ModalModule } from '../modal';
import { LimesurveyInitialQuizModalComponent } from './modals/limesurvey/components/limesurvey-initial-quiz-modal/limesurvey-initial-quiz-modal.component';

/**
 * @description
 * Módulo dos componentes page-default, page-edit, page-list, page-login e page-detail.
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    PoBreadcrumbModule,
    PoDropdownModule,
    PoFieldModule,
    PoModalModule,
    PoButtonModule,
    PoPopoverModule,
    PoAvatarModule,
    PoInfoModule,
    TranslocoModule,
    PoDividerModule,
    ModalModule,
    ReactiveFormsModule,
    PoLoadingModule,
  ],
  declarations: [
    PageComponent,
    PageContentComponent,
    PageDefaultComponent,
    PageHeaderComponent,
    PageInformationComponent,
    PageNotificationButtonComponent,
    LimesurveyInitialQuizModalComponent,
  ],
  exports: [PageDefaultComponent, PageContentComponent, PageInformationComponent],
})
export class PageModule {}
