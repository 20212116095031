// TODO: verify if this ngx-translate method is compatible with transloco
export function platformLangFactory() {
  const fallbackLang = 'pt-BR';
  const browserLang = localStorage.getItem('lang') || window.navigator.language || fallbackLang;

  return /(es-AR|es-CL|es-CO|es-ES|pt-BR|pt-PT)/gi.test(browserLang) ? browserLang : fallbackLang;
}

export function toTimestamp(strDate: string) {
  const datum = Date.parse(strDate);
  return datum / 1000;
}
