import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonGroupComponent } from './button-group.component';
import { PoButtonGroupModule } from '@po-ui/ng-components';

@NgModule({
  declarations: [ButtonGroupComponent],
  imports: [CommonModule, PoButtonGroupModule],
  exports: [ButtonGroupComponent],
})
export class ButtonGroupModule {}
