import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AdvancedSearchFields,
  AdvancedSearchFieldsDisclaimerChange,
} from './advanced-search/model/advanced-search.model';
import { PoDisclaimer } from '@po-ui/ng-components';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';

@Component({
  selector: 'ni-generic-search',
  templateUrl: './generic-search.component.html',
  styleUrls: ['./generic-search.component.scss'],
})
export class GenericSearchComponent {
  @Input() arrayFields: AdvancedSearchFields[] = [];
  @Input() advancedSearch = true;
  @Input() quickSearch = true;
  @Output() filterFields = new EventEmitter<{ [key: string]: unknown }>();
  @Output() quickSearchText = new EventEmitter<string>();
  @ViewChild('advancedSearchComponent')
  advancedSearchComponent: AdvancedSearchComponent;
  disclaimerFields: PoDisclaimer[] = [];

  emitFilterFields(filterFields: { [key: string]: unknown }): void {
    this.filterFields.emit(filterFields);
  }

  emitQuickSearchText(quickSearchText: string): void {
    this.quickSearchText.emit(quickSearchText);
  }

  filter(disclaimerChanges: AdvancedSearchFieldsDisclaimerChange[]): void {
    this.advancedSearchComponent.filter(disclaimerChanges);
  }

  setDisclaimer(disclaimerFields: PoDisclaimer[]) {
    this.disclaimerFields = disclaimerFields;
  }
}
