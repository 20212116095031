<div class="po-table-subtitle-footer-container">
  <div *ngFor="let subtitle of subtitles" class="po-table-subtitle-footer">
    <ni-table-subtitle-circle [p-hide-title]="true" [p-subtitle]="subtitle">
    </ni-table-subtitle-circle>
    <span class="po-table-subtitle-text">{{ subtitle.label }}</span>
  </div>
  <ni-table-show-subtitle
    *ngIf="showSubtitle"
    [p-literals]="literals"
    [p-subtitles]="subtitles"
  >
  </ni-table-show-subtitle>
</div>
