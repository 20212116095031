<div class="d-flex">
  <ni-badge *ngIf="rows?.length > 0" color="table-badge">
    {{ rows[0] }}
  </ni-badge>

  <ni-badge *ngIf="rows?.length > 1" color="table-badge">
    {{ rows[1] }}
  </ni-badge>

  <ni-badge *ngIf="rows?.length === 3" color="table-badge">
    {{ rows[2] }}
  </ni-badge>

  <ni-badge
    *ngIf="rows?.length > 3"
    color="table-badge"
    class="po-clickable"
    [niPopover]="badgePopover"
    position="bottom"
    (click)="selectBadgeList(rows)"
  >
    +{{ rows?.length - 2 }}
  </ni-badge>
</div>

<ng-template #badgePopover>
  <span *ngFor="let item of badgeListSelected; let last = last">
    {{ item + (last ? '' : ',') }}
  </span>
</ng-template>
