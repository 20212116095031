import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TabsComponent } from './tabs.component';
import { TabComponent } from './tab/tab.component';
import { TabButtonComponent } from './tab-button/tab-button.component';
import { SliderButtonsModule } from '../slider-buttons/slider-buttons.module';
import { PoTooltipModule } from '@po-ui/ng-components';
/**
 * @description
 * Módulo do componente ni-tabs
 */
@NgModule({
  imports: [CommonModule, SliderButtonsModule, PoTooltipModule],
  declarations: [TabsComponent, TabComponent, TabButtonComponent],
  exports: [TabsComponent, TabComponent],
})
export class TabsModule {}
