import { Inject, Injectable, InjectionToken, Injector, Optional } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiPrefixInterceptor } from '../interceptors/api-prefix.interceptor';
import { ApiCultureInterceptor } from '../interceptors/api-culture.interceptor';
import { AuthInterceptor } from '../interceptors/auth.interceptor';
import { ErrorHandlerInterceptor } from '../interceptors';
import { RetryPolicyInterceptor } from '../interceptors/retry-policy.interceptor';
import {
  DisableCultureInterceptorToken,
  DisableErrorHandlerToken,
  DisableRetryPolicyToken,
} from './tokens';

// From @angular/common/http/src/interceptor: allows to chain interceptors
class HttpInterceptorHandler implements HttpHandler {
  constructor(private next: HttpHandler, private interceptor: HttpInterceptor) {}

  handle(request: HttpRequest<any>): Observable<HttpEvent<any>> {
    return this.interceptor.intercept(request, this.next);
  }
}

/**
 * Allows to override default dynamic interceptors that can be disabled with the HttpService extension.
 * Except for very specific needs, you should better configure these interceptors directly in the constructor below
 * for better readability.
 *
 * For static interceptors that should always be enabled (like ApiPrefixInterceptor), use the standard
 * HTTP_INTERCEPTORS token.
 */
export const HTTP_DYNAMIC_INTERCEPTORS = new InjectionToken<HttpInterceptor>(
  'HTTP_DYNAMIC_INTERCEPTORS'
);

/**
 * Extends HttpClient with per request configuration using dynamic interceptors.
 */
@Injectable({
  providedIn: 'root',
})
export class HttpService extends HttpClient {
  constructor(
    private httpHandler: HttpHandler,
    private injector: Injector,
    @Optional()
    @Inject(HTTP_DYNAMIC_INTERCEPTORS)
    private interceptors: HttpInterceptor[] = [],
    @Inject(DisableCultureInterceptorToken)
    private disableCultureToken: boolean,
    @Inject(DisableRetryPolicyToken)
    private disableRetryPolicyToken: boolean,
    @Inject(DisableErrorHandlerToken)
    private disableErrorHandlerToken: boolean
  ) {
    super(httpHandler);

    if (!this.interceptors) {
      // Configure default interceptors that can be disabled here
      this.interceptors = [
        this.injector.get(ApiPrefixInterceptor),
        this.injector.get(AuthInterceptor),
      ];

      if (!disableCultureToken) {
        this.interceptors.push(this.injector.get(ApiCultureInterceptor));
      }

      if (!disableRetryPolicyToken) {
        this.interceptors.push(this.injector.get(RetryPolicyInterceptor));
      }

      if (!disableErrorHandlerToken) {
        this.interceptors.push(this.injector.get(ErrorHandlerInterceptor));
      }
    }
  }

  disableApiPrefix(): HttpService {
    return this.removeInterceptor(ApiPrefixInterceptor);
  }

  disableAuthentication(): HttpService {
    return this.removeInterceptor(AuthInterceptor);
  }

  disableErrorHandling(): HttpService {
    return this.removeInterceptor(ErrorHandlerInterceptor);
  }

  disableRetryPolicy(): HttpService {
    return this.removeInterceptor(RetryPolicyInterceptor);
  }

  disableCulture(): HttpService {
    return this.removeInterceptor(ApiCultureInterceptor);
  }

  disableInterceptors(): HttpService {
    return new HttpService(this.httpHandler, this.injector, [], true, true, true);
  }

  // Override the original method to wire interceptors when triggering the request.
  override request(method?: any, url?: any, options?: any): any {
    const handler = this.interceptors.reduceRight(
      (next, interceptor) => new HttpInterceptorHandler(next, interceptor),
      this.httpHandler
    );
    return new HttpClient(handler).request(method, url, options);
  }

  private removeInterceptor(interceptorType: Function): HttpService {
    return new HttpService(
      this.httpHandler,
      this.injector,
      this.interceptors.filter(i => !(i instanceof interceptorType)),
      this.disableCultureToken,
      this.disableRetryPolicyToken,
      this.disableErrorHandlerToken
    );
  }

  private addInterceptor(interceptor: HttpInterceptor): HttpService {
    return new HttpService(
      this.httpHandler,
      this.injector,
      this.interceptors.concat([interceptor]),
      this.disableCultureToken,
      this.disableRetryPolicyToken,
      this.disableErrorHandlerToken
    );
  }
}
