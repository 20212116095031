import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyContentComponent } from './empty-content.component';
import { IconModule } from '../icon';

@NgModule({
  imports: [CommonModule, IconModule],
  declarations: [EmptyContentComponent],
  exports: [EmptyContentComponent],
})
export class EmptyContentModule {}
