import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpService } from './services';
import {
  ApiBaseUrlToken,
  AppStoragePrefixToken,
  DisableCultureInterceptorToken,
  DisableErrorHandlerToken,
  DisableRetryPolicyToken,
  PlatformLanguageToken,
} from './services';
import { PoNotificationModule } from '@po-ui/ng-components';
import { TitanErrorHandler } from './core/error-handler';

export interface TitanConfig {
  apiBaseUrl?: string;
  platformLangFactory?: () => string;
  appStoragePrefix?: string;
  disableCultureInterceptor?: boolean;
  disableRetryPolicy?: boolean;
  disableErrorHandler?: boolean;
}

export function fakePlatformLangFactory() {
  return 'en';
}

@NgModule({
  imports: [CommonModule, PoNotificationModule],
})
export class TitanModule {
  static forRoot(config: TitanConfig = {}): ModuleWithProviders<TitanModule> {
    return {
      ngModule: TitanModule,
      providers: [
        HttpService,
        {
          provide: ApiBaseUrlToken,
          useValue: config.apiBaseUrl || '',
        },
        {
          provide: PlatformLanguageToken,
          useFactory: config.platformLangFactory || fakePlatformLangFactory,
        },
        {
          provide: AppStoragePrefixToken,
          useValue: config.appStoragePrefix || '',
        },
        {
          provide: DisableCultureInterceptorToken,
          useValue: config.disableCultureInterceptor || false,
        },
        {
          provide: DisableRetryPolicyToken,
          useValue: config.disableRetryPolicy || false,
        },
        {
          provide: DisableErrorHandlerToken,
          useValue: config.disableErrorHandler || false,
        },
        {
          provide: ErrorHandler,
          useClass: TitanErrorHandler,
        },
      ],
    };
  }

  static forTest(config: TitanConfig = {}): ModuleWithProviders<TitanModule> {
    return {
      ngModule: TitanModule,
      providers: [
        HttpService,
        {
          provide: ApiBaseUrlToken,
          useValue: config.apiBaseUrl || '',
        },
        {
          provide: PlatformLanguageToken,
          useFactory: config.platformLangFactory || fakePlatformLangFactory,
        },
        {
          provide: AppStoragePrefixToken,
          useValue: config.appStoragePrefix || '',
        },
        {
          provide: DisableCultureInterceptorToken,
          useValue: config.disableCultureInterceptor || false,
        },
        {
          provide: DisableRetryPolicyToken,
          useValue: config.disableRetryPolicy || false,
        },
        {
          provide: DisableErrorHandlerToken,
          useValue: config.disableErrorHandler || false,
        },
      ],
    };
  }
}
