import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TableSubtitleColumn } from './../table-subtitle-footer/table-subtitle-column.interface';
import { ColorPaletteService } from '../services/color-palette.service';

/**
 * @docsPrivate
 *
 * @description
 *
 * Componente para a criação da representação da legenda, em formato de círculo.
 */
@Component({
  selector: 'ni-table-subtitle-circle',
  templateUrl: './table-subtitle-circle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSubtitleCircleComponent {
  private _subtitle: TableSubtitleColumn;

  /** Objeto com os dados da legenda. */
  @Input('p-subtitle') set subtitle(subtitle: TableSubtitleColumn) {
    if (subtitle) {
      subtitle.color = this.poColorPaletteService.getColor(subtitle);
    }
    this._subtitle = subtitle;
  }
  get subtitle(): TableSubtitleColumn {
    return this._subtitle;
  }

  /** Esconde a tooltip. */
  @Input('p-hide-title')
  hideTitle: boolean = false;

  constructor(private poColorPaletteService: ColorPaletteService) {}
}
