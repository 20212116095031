<div class="po-toolbar">
  <div class="po-toolbar-title mobile">
    <ng-content select="img.logo"></ng-content>
  </div>

  <div class="po-toolbar-title big-screen">
    <ng-content select="img.logoPOS"></ng-content>
  </div>

  <div class="po-toolbar-content">
    <!--    <div class="po-mr-4 po-hidden-sm po-hidden-md">-->
    <!--      <ni-icon-->
    <!--        class="po-icon po-pr-1 po-toolbar-image-middle"-->
    <!--        size="medium"-->
    <!--        src="assets/icons/calendar.svg"-->
    <!--      >-->
    <!--      </ni-icon>-->
    <!--      <label class="po-font-text-small">{{ currentDate | date: 'mediumDate' }}</label>-->
    <!--    </div>-->

    <div class="po-toolbar-pipe po-mr-2 po-hidden-sm po-hidden-md"></div>

    <div class="po-mr-2 po-hidden-sm" [hidden]="!themePicker">
      <ng-content select="ni-theme-picker"></ng-content>
    </div>

    <div [hidden]="!themePicker" class="po-toolbar-pipe po-mr-2 po-hidden-sm"></div>

    <div>
      <ng-content select="ni-company-switcher"></ng-content>
    </div>

    <div class="po-toolbar-user po-mr-sm-1">
      <ng-content select="ni-toolbar-menu"></ng-content>
    </div>
  </div>
</div>
