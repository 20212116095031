import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
} from '@angular/common/http';
import { delay, Observable, retryWhen, take, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { DialogService } from '../services/dialog.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({ providedIn: 'root' })
export class RetryPolicyInterceptor implements HttpInterceptor {
  private readonly RETRY_STATUS_CODE = 204;
  private readonly NEEDS_CONFIRMATION_STATUS_CODE = 409;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === this.NEEDS_CONFIRMATION_STATUS_CODE) {
          return this.openConfirmation(error).pipe(
            switchMap((confirmed) => {
              if (confirmed) {
                const retry = request.clone({
                  body: { ...request.body, confirm: true },
                });
                return next.handle(retry);
              }

              return throwError(() => error);
            })
          );
        }

        return throwError(() => error);
      }),
      tap((response: HttpResponse<null>) => {
        if (response.status === this.RETRY_STATUS_CODE) {
          throw response.status;
        }
      }) as HttpSentEvent | HttpResponse<any> | any,
      retryWhen((errors) =>
        errors.pipe(
          delay(500),
          take(20),
          tap((errorStatus) => {
            if (errorStatus !== this.RETRY_STATUS_CODE) throw errorStatus;
          })
        )
      )
    );
  }

  constructor(private dialogService: DialogService) {}

  openConfirmation(response: HttpErrorResponse): Observable<boolean> {
    const promise = new Promise<boolean>((resolve) => {
      this.dialogService.confirm({
        title: response.error?.title,
        message: response.error?.message,
        confirm: () => resolve(true),
        cancel: () => resolve(false),
      });
    });

    return fromPromise(promise);
  }
}
