<po-button
  (p-click)="openModal()"
  [p-label]="'Notificar miHmado no app'"
  p-small="true"
  p-kind="primary"
></po-button>

<po-modal
  #sendNotificationModal
  [p-title]="'Notificar miHmado no app'"
  p-size="sm"
  [p-primary-action]="confirmButton"
  [p-secondary-action]="cancelButton"
>
  <div class="ni-modal-content">
    <po-info
      [p-label]="'mihmados.mihmado' | transloco"
      [p-value]="userSelected?.name"
      class="po-md-8"
    ></po-info>

    <form [formGroup]="notificationForm">
      <po-textarea
        [(ngModel)]="body"
        [p-label]="(body ? body.length.toString() : '0') + '/100'"
        [p-maxlength]="100"
        class="po-md-12 po-mt-1 send-push-notification-text-area"
        formControlName="body"
      ></po-textarea>
    </form>
  </div>
</po-modal>
