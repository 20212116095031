import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericSearchComponent } from './generic-search.component';
import {
  PoButtonModule,
  PoDisclaimerGroupModule,
  PoFieldModule,
  PoModalModule,
} from '@po-ui/ng-components';
import { TranslocoModule } from '@ngneat/transloco';
import { QuickSearchComponent } from './quick-search/quick-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    GenericSearchComponent,
    QuickSearchComponent,
    AdvancedSearchComponent,
  ],
  imports: [
    CommonModule,
    PoButtonModule,
    PoFieldModule,
    PoDisclaimerGroupModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    PoModalModule,
    DropdownModule,
  ],
  exports: [GenericSearchComponent, QuickSearchComponent],
})
export class GenericSearchModule {}
